import React from "react";
import { makeRequest, PaymentModel } from "../../common/api";
import { isUserOwner } from "../../common/auth";
import { justConfirm } from "../../common/components/Modals";
import { EditPaymentModelForm, getPaymentModelDescription } from "../../common/features/EditPaymentModelForm";
import { arrayRemove, FlexRow, ListItem, ListItemBody, LoadingIndicator, PageTitle, useTitle, ZeroState } from "../../common/features/Utils";
import { OrgIdContext } from "../../common/OrgContext";
import { UserContext } from "../../common/UserContext";


export const PaymentModels = () => {
    useTitle("Payment Models");
    const user = React.useContext(UserContext)?.user;
    const orgId = React.useContext(OrgIdContext);
    const [isAdding, setIsAdding] = React.useState(false);
    const [selectedPaymentModel, setSelectedPaymentModel] = React.useState<PaymentModel | null>(null);
    const [paymentModels, setPaymentModels] = React.useState<PaymentModel[] | null>(null);

    const loadData = () => {
        if (user) {
            makeRequest("/api/paymentModels", "GET").then(models => {
                setPaymentModels(models);
            });
        }
    };
    React.useEffect(loadData, [user]);

    const deletePaymentModel = (model: PaymentModel) => justConfirm(`Are you sure you want to delete ${model.name}?`).then(() => {
        makeRequest(`/api/paymentModels/${model._id}`, "DELETE").then(() => {
            setPaymentModels(arrayRemove(paymentModels, paymentModels.indexOf(model)));
        });
    });

    return (<div>
        <PageTitle>Your Payment Models</PageTitle>
        <div>
            {paymentModels === null && <div style={{ textAlign: "center", padding: "20px" }}><LoadingIndicator size="4x" /></div>}

            {!isAdding && !selectedPaymentModel &&
                <>
                    {paymentModels?.length === 0 && <ZeroState>Your organization hasn't created any Payment Models yet. Create one now?</ZeroState>}
                    {paymentModels?.map(paymentModel => (<ListItem key={paymentModel._id}>
                        <FlexRow>
                            <div>
                                <span><b>{paymentModel.name}</b></span>
                                {isUserOwner(user, orgId) && <>
                                    <button className="inline secondary"
                                        onClick={() => setSelectedPaymentModel(paymentModel)}>
                                        Edit
                                    </button>{"  "}
                                    <button className="inline secondary" onClick={() => deletePaymentModel(paymentModel)}>
                                        Delete
                                    </button>
                                </>}
                                <ListItemBody>
                                    {getPaymentModelDescription(paymentModel)}
                                </ListItemBody>
                            </div>
                        </FlexRow>
                    </ListItem>))}
                    <br />
                    {
                        isUserOwner(user, orgId) &&
                        <button onClick={() => setIsAdding(true)}>New payment model</button>
                    }
                </>}
        </div>
        {isAdding &&
            <EditPaymentModelForm
                paymentModel={null}
                isAdding={true}
                onChange={() => {
                    setIsAdding(false);
                    setPaymentModels(null);
                    loadData();
                }}
                onClose={() => setIsAdding(false)} />
        }


        {
            selectedPaymentModel && (
                <div>
                    <h3>Edit payment model</h3>
                    <EditPaymentModelForm
                        paymentModel={selectedPaymentModel}
                        onChange={() => {
                            setSelectedPaymentModel(null);
                            setPaymentModels(null);
                            loadData();
                        }}
                        onClose={() => {
                            setSelectedPaymentModel(null);
                        }}
                    />
                </div>
            )
        }
    </div>);
};