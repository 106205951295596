import React from "react";
import { Application, DynamicConditionTerm } from "../../common/api";
import { checkConditionTerm } from "../../common/features/DynamicConditions";

declare var Plotly;

interface Props {
  applications: Application[];
  mappings: { [key: string]: string };
}
interface State {
  selectedGroupByName: string;
  secondaryGroupBy: string;
}
export class DashboardTrendsPage extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectedGroupByName: CONDITIONS[0].name,
      secondaryGroupBy: "",
    };
  }

  componentDidMount() {
    const existingScript = document.getElementById("plotly-script");
    if (!existingScript) {
      const scriptTag = document.createElement("script");
      scriptTag.id = "plotly-script";
      scriptTag.src = "/lib/plotly.js";
      scriptTag.type = "text/javascript";
      scriptTag.onload = () => this.renderPlot();
      document.body.appendChild(scriptTag);
    } else {
      this.renderPlot();
    }
  }

  componentDidUpdate() {
    setTimeout(() => this.renderPlot(), 30);
  }

  renderPlot() {
    const term = CONDITIONS.find(
      (condition) => condition.name === this.state.selectedGroupByName
    );
    const secondaryTerm = this.state.secondaryGroupBy
      ? CONDITIONS.find(
          (condition) => condition.name === this.state.secondaryGroupBy
        )
      : null;
    const data = groupByDate(
      this.props.applications,
      term,
      secondaryTerm,
      this.props.mappings
    );
    Plotly.newPlot("plotlyRoot", data, { barmode: "stack" });
  }

  render() {
    return (
      <div>
        <p>
          'Trends' is a work in progress. Please contact Michael for feature
          requests.
        </p>
        <label style={{ width: "auto" }}>
          Group by:
          <select
            value={this.state.selectedGroupByName}
            onChange={(e) =>
              this.setState({
                selectedGroupByName: e.target.value,
                secondaryGroupBy: "",
              })
            }
          >
            {CONDITIONS.map((condition) => (
              <option key={condition.name} value={condition.name}>
                {condition.name}
              </option>
            ))}
          </select>
        </label>
        <label style={{ marginLeft: "40px", width: "auto" }}>
          Secondary group by:
          <select
            value={this.state.secondaryGroupBy}
            onChange={(e) =>
              this.setState({ secondaryGroupBy: e.target.value })
            }
          >
            <option key={""} value={""}>
              None
            </option>
            {CONDITIONS.filter(
              (c) => c.name !== this.state.selectedGroupByName
            ).map((condition) => (
              <option key={condition.name} value={condition.name}>
                {condition.name}
              </option>
            ))}
          </select>
        </label>
        <div id="plotlyRoot"></div>
      </div>
    );
  }
}

// Either a condition, or a field that is group-by-ed
interface DynamicPivot extends DynamicConditionTerm {
  name: string;
  excludedName?: string; // The name of an item that doesn't meet the condition
}

const CONDITIONS: DynamicPivot[] = [
  {
    name: "Joined",
    excludedName: "Not joined",
    target: "STATUS",
    operator: "Greater than",
    comparisonValue: "CONTRACT SENT",
  },
  {
    name: "Batch",
    target: "FIELD",
    targetDetails: "batchId",
    operator: "Group by",
  },
  {
    name: "Tracking",
    target: "TRACKING",
    operator: "Group by",
  },
];

const groupByDate = (
  applications: Application[],
  term: DynamicPivot,
  secondaryTerm: DynamicPivot | null,
  mappings: { [key: string]: string }
) => {
  const dataMap = {};

  for (let application of applications) {
    const date = new Date(application.createdAt);
    date.setHours(0, 0, 0, 0);
    const dateString = date.toISOString();
    const isAMatch = checkConditionTerm(term, application, []);
    const dataKey = computeDataKey(term, isAMatch, application, mappings);
    if (secondaryTerm) {
      const isASecondaryMatch = checkConditionTerm(
        secondaryTerm,
        application,
        []
      );
      const secondaryDataKey = computeDataKey(
        secondaryTerm,
        isASecondaryMatch,
        application,
        mappings
      );
      addOrIncrementData(
        dataMap,
        dataKey + " > " + secondaryDataKey,
        dateString
      );
    } else {
      addOrIncrementData(dataMap, dataKey, dateString);
    }
  }

  return Object.values(dataMap);
};

const addOrIncrementData = (
  dataMap: any,
  dataKey: string,
  dateString: string
) => {
  if (!dataMap[dataKey]) {
    dataMap[dataKey] = {
      x: [],
      y: [],
      name: dataKey,
      type: "bar",
    };
  }
  const data = dataMap[dataKey];

  if (data.x[data.x.length - 1] === dateString) {
    data.y[data.x.length - 1]++;
  } else {
    data.x.push(dateString);
    data.y.push(1);
  }
};

const computeDataKey = (
  term: DynamicPivot,
  isAMatch: boolean,
  entry: any,
  mappings: { [key: string]: string }
) => {
  if (term.operator === "Greater than" || term.operator === "Exists") {
    return isAMatch ? term.name : term.excludedName || "Not " + term.name;
  } else if (term.target === "FIELD" && term.targetDetails) {
    const value = entry[term.targetDetails];
    return mappings[value] || value;
  } else if (term.target === "TRACKING") {
    const tracking = entry.tracking;
    if (tracking) {
      return entry.tracking.source + " / " + entry.tracking.campaign + " / " + entry.tracking.content;
    } else {
      return "No tracking";
    }
  } else {
    // Unexpected case
    return term.name;
  }
};
