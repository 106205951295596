import React from "react"

interface SelectOption {
  value: string;
  label?: string;
}

interface Props {
  object: any;
  propName: string;
  onChange: (object: any) => void;
  label?: String | React.ReactNode;
  disabled?: boolean;
}
export const TextInput = (props: Props) => {
  return <div>
    <label>
      {props.label || props.propName}
    </label>
    <input type="text" value={props.object[props.propName] || ""} disabled={props.disabled}
      onChange={e => props.onChange({ ...props.object, [props.propName]: e.target.value })} />
  </div>
}

interface ParagraphProps extends Props {
  rows: number,
  cols: number
}
export const ParagraphInput = (props: ParagraphProps) => {
  return <div>
    <label>
      {props.label || props.propName}
    </label>
    <textarea rows={props.rows} cols={props.cols} value={props.object[props.propName]} disabled={props.disabled}
      onChange={e => props.onChange({ ...props.object, [props.propName]: e.target.value })} />
  </div>
}

export const BoolInput = (props: Props) => {
  return <div>
    <label style={{ width: "200px" }}>
      {props.label || props.propName}
      <input style={{ width: "30px", marginLeft: "20px" }} type="checkbox" checked={!!props.object[props.propName]} disabled={props.disabled}
        onChange={e => props.onChange({ ...props.object, [props.propName]: e.target.checked })} />
    </label>
  </div>
};

interface SelectProps extends Props {
  options: SelectOption[];
}
export const SelectInput = (props: SelectProps) => {
  return <div>
    <label style={{ width: "200px" }}>
      {props.label || props.propName}
      <select value={props.object[props.propName]}
        onChange={e => props.onChange({ ...props.object, [props.propName]: e.target.value })}>
        {props.options.map((o) => <option value={o.value} key={o.value}>
          {o.label || o.value}
        </option>)}
      </select>
    </label>
  </div>;
};