import { Organization } from "../api";

let analyticsConfigured = false;
export const setupAnalytics = (org: Organization) => {
    if (analyticsConfigured) {
        return;
    }

    const w: any = window;

    // Replace potentially problematic characters
    let gId = org.googleAnalyticsId?.replace(/[/\\^$*+?.()|[\]{}'"`]/g, 'XXXXXXX');
    if (gId && gId.length <= 16) {
        const scriptTag = document.createElement("script");
        scriptTag.async = true;
        scriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${gId}`;
        document.head.appendChild(scriptTag);

        w.dataLayer = w.dataLayer || [];
        w.gtag = function () { w.dataLayer.push(arguments); }
        w.gtag('js', new Date());
        w.gtag('config', gId);

        analyticsConfigured = true;
    }

    // eslint-disable-next-line
    const fId = org.facebookPixelId?.replace(/[/\\^$*+?.()|[\]{}'"`]/g, 'XXXXXXX');
    if (fId && fId.length < 18) {
        // eslint-disable-next-line
        eval(`!function (f, b, e, v, n, t, s) {
			if (f.fbq) return; n = f.fbq = function () {
				n.callMethod ?
					n.callMethod.apply(n, arguments) : n.queue.push(arguments)
			};
			if (!f._fbq) f._fbq = n; n.push = n; n.loaded = !0; n.version = '2.0';
			n.queue = []; t = b.createElement(e); t.async = !0;
			t.src = v; s = b.getElementsByTagName(e)[0];
			s.parentNode.insertBefore(t, s)
		}(window, document, 'script',
			'https://connect.facebook.net/en_US/fbevents.js');`)
        w.fbq('init', fId);
        w.fbq('track', 'PageView');
        analyticsConfigured = true;
    }
}

// If configured, this will send an event name to Google, Facebook
// or other analytics providers configured by the organization
export const trackAnalyticsEvents = (eventName) => {
    if (!analyticsConfigured) {
        return;
    }

    try {
        const w: any = window;
        if (w.gtag) {
            w.gtag('event', eventName);
        }

        if (w.fbq) {
            w.fbq('track', eventName);
        }
    } catch (ex) {
        // TODO: Report failure
    }
}

export const trackPurchaseEvent = (currency: string, value: number) => {
    if (!analyticsConfigured) {
        return;
    }

    try {
        const w: any = window;
        if (w.gtag) {
            w.gtag('event', "purchase", {currency, value});
        }

        if (w.fbq) {
            w.fbq('track', "Purchase", {currency, value});
        }
    } catch (ex) {
        // TODO: Report failure
    }

}

let queryParams: any = null;
export const captureQueryParams = () => {
    queryParams = new URLSearchParams(window.location.search);
}
export const getCapturedQueryParams: () => URLSearchParams | null = () => {
    return queryParams;
}