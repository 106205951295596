import React from "react";
import styled from "styled-components";

const HeaderBox = styled.div<{ height: number }>`
  height: ${p => p.height}px;
  width: 100%;
  text-align: center;

  position: relative;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-bottom: 10px;
`;

const HeaderBg = styled.div<{ imageUrl: string }>`
  background-image: url(${p => p.imageUrl});
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  -webkit-filter: blur(1px) brightness(0.5);
  -moz-filter: blur(1px) brightness(0.5);
  -ms-filter: blur(1px) brightness(0.5);
  -o-filter: blur(1px) brightness(0.5);
  filter: blur(1px) brightness(0.5);

  background-size: cover;
`;

const HeaderContent = styled.h3`
  color: #F7F7F7;
  z-index: 2;
  padding: 20px;
`;

interface Props {
  text: string;
  imageUrl: string;
  height: number;
}
export const HeroHeader = (props: Props) => {
  return (<HeaderBox height={props.height}>
    <HeaderBg imageUrl={props.imageUrl}></HeaderBg>
    <HeaderContent>{props.text}</HeaderContent>
  </HeaderBox>);
};