/**
 * This file contains the client-side types for the data models defined
 * in the server's `models.js` file. These two files must be manually
 * kept in sync.
 */

// Used to prevent certain actions from occurring for logged-in admins.
// Setting this to `true` does not grant any additional access.
export const IS_ADMIN_REF = {
  isAdmin: false
};

export type Role = "Owner" | "Admin" | "Viewer";

export interface FileInfo {
  fileId: string;
  fileName: string;
  cdnUrl?: string;
}

export interface Organization {
  _id: string;
  name: string;

  description: string;
  logoFile: FileInfo | null;
  iconFile: FileInfo | null;
  ownerId: string;

  slug: string;
  subdomain: string;
  fullDomain: string;

  sendGridApiKey: string;
  sendGridEmailAddress: string;

  googleAnalyticsId: string;
  facebookPixelId: string;
}

export interface SignupLink {
  email: string;
  token: string;
  orgToUse: string;
  roleToUse: Role;
}

export interface RoleEntry {
  orgId: string;
  role: Role;
}
export interface User {
  _id: string;
  email: string;
  isAdmin?: boolean;

  orgId?: string;
  orgRoles?: RoleEntry[];

  /** If true, always show the user their management tools in addition to application status. */
  isCreator?: boolean;
  roles?: Role[];
}

export const BATCH_STATUS = ["Draft", "Open", "Closed", "Complete"];
type BatchStatus = "Draft" | "Open" | "Closed" | "Complete";

// TODO: Extract these out to be shared with server.js for type safety?
export interface Batch {
  _id: string;
  organizationId?: string;

  name: string;
  description: string;
  privateDetails: string; // Markdown
  capacity: number;
  assessmentIds: string[] | null;
  status?: BatchStatus;
  slug?: string;

  processId?: string;
  enableEmbedding?: boolean;

  iconFile: FileInfo | null;
  bannerFile: FileInfo | null;

  createdAt?: string;
  updatedAt?: string;
}

export interface Solution {
  _id: string;
  organizationId?: string;

  userId: string;
  assessmentId: string;
  applicationId: string; // This is a bit unfortunate...
  startedAt: Date,
  submittedAt: Date | null,
  submission: FileInfo | null;
  answers: { value: string }[];

  externalDetailsUrl?: string;

  // if isRetaken is true, the solution is "outdated"
  isRetaken: boolean;
}

export interface AdminComment {
  message: string;
  postedDate: Date;
  authorId: string;
  authorEmail: string;
}

export interface Application {
  _id: string;
  organizationId: string;

  batchId: string;
  batch?: Batch;
  userId: string;
  status: string;

  selectedPaymentModelId?: string;

  // Custom Fields
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  resume: FileInfo | null;
  motivationEssay: string;
  solutions: string[] | null;

  // Temporary usage: Show on the dashboard
  projectFile: FileInfo | null;
  projectFiles: FileInfo[] | null;

  calendlyEventUri?: string;
  calendlyJoinUrl?: string;
  calendlyStartTime?: string;

  /** Admin-only fields */
  assessmentScores: AssessmentScore[];

  adminComments: AdminComment[] | null;
  adminAssignee?: { userId: string; userEmail: string; }
  completedContract: FileInfo | null;

  trackingNumber?: number;
  tracking: {
    source: string,
    medium: string,
    campaign: string,
    term: string,
    content: string
  },

  userActivity?: {
    activityEntries: {
      _id?: string,
      action: string,
      actionDetails?: string,
      timestamp: string
    }[]
  },

  createdAt: string;
  updatedAt: string;
}

export interface AssessmentScore {
  solutionId: string;
  assessmentId: string;
  score: number;
}

export interface Question {
  prompt: string;
  imageUrl: string;
  details: string;
  options: QuestionOption[];
}

export interface QuestionOption {
  value: string;
  isCorrect: boolean;
}

export type AssessmentType = "quiz" | "project" | "external";

export interface Assessment {
  _id: string;
  organizationId: string;

  name: string;
  description: string;
  instructions: string;
  timeLimitMinutes: number;

  type: AssessmentType;

  attachment: FileInfo | null;
  answerKey: FileInfo | null;

  externalAssessmentUrl?: string;
  messagingSecretToken?: string;

  questions: Question[];
}

export interface EmailTemplate {
  _id: string;
  organizationId: string;
  name: string;
  subject: string;
  body: string;
  attachments?: FileInfo[];

  hasSpecialName?: boolean;
}

export interface PaymentModel {
  _id: string;
  organizationId: string;

  name: string;
  details: string;

  upfrontCost: number;
  monthlyCost: number;
  numberOfMonths: number;
  monthlyPaymentDay: number;
  monthlyPaymentDelay: number; // How many months they get free before payments start
  currency: string;
}

/** 
 * Hard coded default list of status values, which may eventually become customizeable
 */
export const STATUS_VALUES = [
  "UNRESPONSIVE",
  "WITHDRAWN",
  "CANCELLED",
  "NEW",
  "INTERVIEW PENDING",
  "FINANCE PENDING",
  "READY FOR ENROLLMENT",
  "CONTRACT PENDING",
  "CONTRACT SENT",
  "CONTRACT RECEIVED",
  "PRE-WORK SENT",
  "PRE-WORK RECEIVED",
  "COMPLETE"];

/**
 * An application form that is controlled by the batch configuration
 * provided by the organization who is controlling the current instance
 * of the Applicata service.
 */

export type FieldDataType = "text" | "paragraph" | "file" | "tel" | "email";

export interface FieldDefinition {
  name: string;
  label: string;
  dataType: FieldDataType;

  isAdminOnly?: boolean;
}

export interface DynamicFieldConfig extends FieldDefinition {
  required?: boolean;

  /** Otional markdown rendered above the input field. */
  details?: string;
}

export interface DynamicFormConfig {
  // What API to call when the user submits. Supports ':entryId' replacement token.
  route: string;
  method: "POST" | "PUT";

  fields: DynamicFieldConfig[];
  title?: string;
}

export type DynamicConditionTarget = "ASSESSMENTS_COMPLETE" | "STATUS" | "FIELD" | "TRACKING";
export const DynamicConditionTargetOptions
  : DynamicConditionTarget[] = ["ASSESSMENTS_COMPLETE", "STATUS", "FIELD"];

  // "Group by" as an option is not used in the condition editor, only in the
  // chart controls on the trends page
export type DynamicConditionOperator = "Greater than" | "Exists" | "Group by";
export const DynamicConditionOperatorOptions
  : DynamicConditionOperator[] = ["Greater than", "Exists"];
export interface DynamicConditionTerm {
  target: DynamicConditionTarget;
  targetDetails?: string; // Allows indexing, in the case of Field for example. Could extend with number if indexing an array
  operator: DynamicConditionOperator,
  comparisonValue?: number | string;
}

export interface DynamicCondition {
  logicalOperator: "AND" | "OR";
  terms: DynamicConditionTerm[];
}

// Note: While this is intended to be somewhat generic, my only aim at the moment is to enable
// a dynamic calendly integration. I don't know if this is the best naming convention or data model,
// but it seems like the shortest path to get where I want.
export interface DynamicIntegration {
  type: "Calendly";
  url: string;
}

export interface DynamicContent {
  markdown: string;
  condition?: DynamicCondition; // Some specification to require a certain condition
  
  // As of 1/12/2022, only provides the option to add Calendly to a block
  integrations?: DynamicIntegration[];
}
export interface ProcessStep {
  title: string;
  subtitle: string;

  // If available, makes this process step render dynamic content
  content?: DynamicContent[];

  // If available, makes this process step a dynamic form
  configuration?: DynamicFormConfig;

  // If available, this still will render payment model selection.
  availablePaymentModels?: string[];

  // If available, makes this process step an assessment bucket
  assessmentIds?: string[];

  // If provided, will calculate whether the step is visible
  visibilityCondition?: DynamicCondition;

  // If provided, will calculate the step's completion status, otherwise
  // status is automatically computed based on the form config or assessment ids
  completionCondition?: DynamicCondition;
}
export interface Process {
  // Currently this is not stored in the JSON and needs to be manually copied onto the frontend blob representation
  _id?: string;

  label: string;
  fields?: FieldDefinition[];
  applicationForm: DynamicFormConfig;
  applicationDetailsSteps: ProcessStep[];

  requestSignup?: boolean;

  triggerIds: string[];
}

export interface TriggerAction {
  targetId: string;
}
export interface Trigger {
  _id: string;
  organizationId: string;
  name: string;
  isActive: boolean;
  condition: DynamicCondition;
  action: TriggerAction;
}

export interface InvoiceDetails {
  dueDate: string,
  dueAmount: number,
  currency: number,
  details: string,
  isComplete: boolean
};

export interface PaymentDetails {
  date: string,
  amount: number,
  currency: number,
  details: string,
  attachments: FileInfo[],
}

// Can be used on an Application, User or other to track payments
export interface PaymentAccount {
  _id: string,
  userId: string,
  organizationId: string,
  applicationId: string,


  totalBalance: number,
  currency: string,
  // Tracks all invoices, both past and future
  invoices: InvoiceDetails[],
  paymentsMade: PaymentDetails[],
  originalPaymentModel: PaymentModel

  // For record-keeping, a payment account should generally not be deleted,
  // but instead closed once balance is zeroed out.
  isClosed: boolean
};

export interface Webhook {
  _id: string;
  organizationId: string;

  name: string;
  targetUrl: string;
}

export interface ErrorLogEntry {
  _id: string;
  organizationId: string;

  message: string;

  createdAt: string;
}

export const makeRequest = (url: string, method: string, body?: any) => {
  if (window.location.hostname === "dreamstack.app" ||
    window.location.hostname === "www.dreamstack.app" ||
    window.location.hostname === "localhost") {
    const params = new URLSearchParams(window.location.search);
    const orgIdParam: any = params.get("organizationId");
    if (orgIdParam) {
      url += url.includes("?") ? "&" : "?";
      url += "organizationId=" + orgIdParam;
    } else if (window.localStorage.getItem("organizationId")) {
      url += url.includes("?") ? "&" : "?";
      url += "organizationId=" + window.localStorage.getItem("organizationId");
    }
  }

  return fetch(url, {
    method,
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(body)
  }).then(async (response) => {
    if (response.status === 200) {
      return response.json();
    }

    let errorMessage = "";
    try {
      const json = await response.json();
      if (json && json.message) {
        errorMessage = json.message;
      }
    } catch (ex) {

    }
    throw new Error(errorMessage || "Request failed: " + response.statusText);
  });
};


// NOTE(Michael): I am not particularly happy with this API format of requiring
// enum values for targetType and uploadType. At least I'd love to find a better
// teminology to represent what all this means
export type TargetType = "application" | "user" | "assessment" | "batch" | "solution" | "image" | "emailTemplate";
export type UploadType = "resume" | "attachment" | "answerKey" | "submission" | "image" | "contract" | "other";
export const makeUploadRequest = (targetType: TargetType, targetId: string, uploadType: UploadType, file: any, orgId: string) => {
  const formData = new FormData();
  formData.append("targetType", targetType);
  formData.append("targetId", targetId);
  formData.append("uploadType", uploadType);

  formData.append("file", file);
  formData.append("fileName", file.fileName);
  if (orgId) {
    formData.append("organizationId", orgId);
  }

  return fetch(
    "/api/upload", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      // TODO: Determine if these headers are needed for upload to work better?
      // "Accept": "application/json",
      // "Content-Type": "multipart/form-data"
    },
    body: formData
  }).then((response) => response.json());
}

/**
 * Makes a request to upload a file and get back the fileInfo. Used
 * to support file uploads as part of a dynamic form.
 */
export const makeDynamicUploadRequest = (uploaderEmail: string, uploadType: string, file: any, orgId?: string) => {
  const formData = new FormData();
  formData.append("uploaderEmail", uploaderEmail);
  formData.append("uploadType", uploadType);

  formData.append("file", file);
  formData.append("fileName", file.fileName);

  if (orgId) {
    formData.append("organizationId", orgId);
  }

  return fetch(
    "/api/dynamicUpload", {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {},
    body: formData
  }).then((response) => response.json());
};

let availableAdmins: User[] | null = null;
export const getAvailableAdmins = async () => {
  if (availableAdmins) {
    return availableAdmins;
  }

  try {
    const response = await makeRequest("/api/users?filter=admin", "GET");

    if (response) {
      availableAdmins = response;
      return response;
    }
  } catch (ex) {
    // alert("Failed to fetch available admins: " + ex.message);
  }

  return null;
};