import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { makeRequest, Webhook } from "../../common/api";
import { justConfirm } from "../../common/components/Modals";
import {
  ListItem,
  ListItemBody,
  LoadingPage,
  PageTitle,
  useTitle,
} from "../../common/features/Utils";
import { EditWebhookForm } from "./EditWebhookForm";

const Pre = styled.pre`
  display: inline;
  padding: 4px;
`;

/** Admin tool for managing email templates */
export const Webhooks = () => {
  useTitle("Webhooks");
  const [webhooks, setWebhooks] = React.useState<Webhook[] | null>(null);
  const [activeWebhook, setActiveWebhook] = React.useState<Webhook | null>(
    null
  );
  const [isAdding, setIsAdding] = React.useState<boolean>(false);

  const fetchWebhooks = () => {
    setWebhooks(null);
    makeRequest("/api/webhooks", "GET").then((response) =>
      setWebhooks(response)
    );
  };
  React.useEffect(() => {
    fetchWebhooks();
  }, []);

  const tryDeleteWebhook = (webhook: Webhook) => {
    justConfirm(`Are you sure you want to delete this webhook?`).then(() => {
      makeRequest(`/api/webhooks/${webhook._id}`, "DELETE").then(
        () => {
          setWebhooks(
            [...(webhooks || [])].filter((x) => x._id !== webhook._id)
          );
        },
        (err) => alert("Failed to delete: " + err.message)
      );
    });
  };

  if (!webhooks) {
    return <LoadingPage />;
  }

  return (
    <div>
      <PageTitle>Your Webhooks</PageTitle>
      <p>
        Add webhooks to get automatically notified when important events occur.
        Currently only supports Calendly events. Format configured for Discord:{" "}
        <Pre>{`{"content": "test"}`}</Pre>
      </p>
      {!isAdding &&
        !activeWebhook &&
        webhooks.map((webhook) => (
          <ListItem key={webhook.targetUrl}>
            <b>{webhook.name}</b>
            <button
              className="secondary inline"
              onClick={() => setActiveWebhook(webhook)}
            >
              Edit
            </button>
            <button
              className="secondary inline"
              onClick={() => tryDeleteWebhook(webhook)}
            >
              Delete
            </button>
            <ListItemBody>
              <pre>{webhook.targetUrl}</pre>
            </ListItemBody>
          </ListItem>
        ))}
      <div>
        {!isAdding && !activeWebhook && (
          <button className="" onClick={() => setIsAdding(true)}>
            <FontAwesomeIcon icon="plus" /> New webhook
          </button>
        )}
      </div>
      <div>
        {(isAdding || activeWebhook) && (
          <EditWebhookForm
            webhook={activeWebhook}
            onSave={async (webhook: Webhook) => {
              (await isAdding)
                ? makeRequest("/api/webhooks", "POST", webhook)
                : makeRequest(`/api/webhooks/${webhook._id}`, "PUT", webhook);
              if (isAdding) {
                setIsAdding(true);
                setWebhooks(null);
              }

              setIsAdding(false);
              setActiveWebhook(null);
              fetchWebhooks();
            }}
            onClose={() => {
              setIsAdding(false);
              setActiveWebhook(null);
            }}
          />
        )}
      </div>
    </div>
  );
};
