import styled from "styled-components";


export const Card = styled.div`
    display: inline-block;
    background-color: white;
    border-radius: 5px;
    border: 1px solid #999;
    margin: 10px 20px;
    padding: 20px;
    width: auto;
    font-size: 18px;
`;