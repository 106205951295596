import React from "react";
import styled from "styled-components";

const Body = styled.div`
  margin-bottom: 10px;
`;

const Text = styled.div`
  display: inline-block;
  min-width: 100px;
  padding: 7px 10px;
  margin: 7px 10px;
  cursor: pointer;
  text-align: center;
  font-size: 18px;

  &:hover {
    color: #589;
  }

  &.selected {
    font-weight: 700;
    border-bottom: 2px solid black;
    cursor: initial;
  }

  &.selected:hover {
    color: initial;
  }

  &:first-letter {
    text-transform: capitalize;
  }
`;

interface Props {
  tabs: string[];
  onClick: (tabName: string, index: number) => void;
  saveToQueryString?: boolean;
}

export const TabBar = (props: Props) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  React.useEffect(() => {
    if (props.saveToQueryString) {
      const params = new URLSearchParams(window.location.search);
      const tab: any = params.get("tab");
      const index = props.tabs.indexOf(tab);
      if (index >= 0) {
        props.onClick(props.tabs[index], index);
        setSelectedIndex(index);
      }
    }
    // I DO NOT want to re-run this on saveToQueryString or props.tabs
    // eslint-disable-next-line
  }, []);

  return (<Body>
    {
      props.tabs.map((name, index) => (
        <Text key={name} className={index === selectedIndex ? "selected" : ""} onClick={() => {
          setSelectedIndex(index);
          if (props.saveToQueryString) {
            saveToQueryString("tab", props.tabs[index]);
          }
          props.onClick(name, index);
        }}>
          {name}
        </Text>
      ))
    }
  </Body>)
};

const saveToQueryString = (key: string, value: string) => {
  const params = new URLSearchParams(window.location.search);
  params.set(key, value);
  let newUrl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?' + params.toString();
  window.history.pushState({ path: newUrl }, '', newUrl);
};