import React from "react";
import { Assessment } from "../../common/api";
import { formatMinutes, ListItem, ListItemBody, PageTitle, useTitle, ZeroState } from "../../common/features/Utils";
import { EditAssessmentForm } from "./EditAssessmentForm";

interface Props {
    assessments: Assessment[];
    onChange: () => void;
}
export const Assessments = (props: Props) => {
    useTitle("Assessments");
    const [isAdding, setIsAdding] = React.useState(false);
    const [selectedAssessment, setSelectedAssessment] = React.useState<Assessment | null>(null);

    return (<div style={{ marginTop: "20px" }}>
        <PageTitle>Your Assessments</PageTitle>
        {props.assessments === null && "Loading..."}
        {props.assessments?.length === 0 && (<ZeroState>
            Your organization does not have any assesments yet. Create a quiz or project now?
        </ZeroState>)}
        {!selectedAssessment && props.assessments?.map(assessment => (<ListItem key={assessment._id}>
            <span><b>{assessment.name}</b> {assessment!.timeLimitMinutes && "(" + formatMinutes(assessment!.timeLimitMinutes) + ")"}
            <button className="inline secondary"
                    onClick={() => setSelectedAssessment(assessment)}>
                    Edit
            </button>
            </span>
            <ListItemBody>
                {assessment.description?.substring(0, 200)}
                {assessment.description?.length > 200 && "..."}
            </ListItemBody>
        </ListItem>))}
        {selectedAssessment && <EditAssessmentForm assessment={selectedAssessment} onChange={() => {
            setSelectedAssessment(null);
            props.onChange();
        }} onClose={() => setSelectedAssessment(null)} />}
        {!selectedAssessment && (<>
            {isAdding ? <EditAssessmentForm onClose={() => setIsAdding(false)}
                onChange={() => {
                    setIsAdding(false);
                    props.onChange()
                }} /> : <button onClick={() => setIsAdding(true)}>New Assessment</button>}
        </>)
        }
    </div>);
}