import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";

export const SideBarPageBody = styled.div`
  display: flex;
  height: calc(100% - 71px);
  overflow: hidden;
  gap: 10px;
`;

const Body = styled.div`
  flex: 0 0 auto;
  box-shadow: 0px 0px 2px 0px rgb(0 0 0 / 60%);
  background-color: rgb(239, 234, 241);

  display: flex;
  flex-direction: column;

  width: 200px;
`;

const Title = styled.h5`
  padding: 20px 20px 30px 20px;
  margin-bottom: 30px;
  border-bottom: 1px solid rgba(160, 170, 180, 0.5);
`;

const Item = styled.div`
  display: inline-block;
  min-width: 100px;
  padding: 15px;
  cursor: pointer;
  font-size: 14px;

  color: #484a4d;

  &:hover {
    background-color: rgb(222, 209, 222);
  }

  &.selected {
    color: #10181f;
    background-color: white;
    font-weight: 700;
    cursor: initial;
  }

  &.selected:hover {
    color: initial;
  }

  &:first-letter {
    text-transform: capitalize;
  }
`;

interface Props {
  title: string;
  tabs: string[];
  displayNames?: string[];
  onClick: (tabName: string, index: number) => void;
  saveToQueryString?: boolean;

  // If provided, will show the location that this page is nested under,
  // with an optional href allowing navigation back up the site hierarchy.
  parent?: string;
  parentHref?: string;
}

// Copied from TabBar, but renders vertically and supports a wider range
// of navigation options.
export const SideBar = (props: Props) => {
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const history = useHistory();

  React.useEffect(() => {
    if (props.saveToQueryString) {
      const params = new URLSearchParams(window.location.search);
      const tab: any = params.get("tab");
      const index = props.tabs.indexOf(tab);
      if (index >= 0) {
        props.onClick(props.tabs[index], index);
        setSelectedIndex(index);
      }
    }
    // I DO NOT want to re-run this on saveToQueryString or props.tabs
    // eslint-disable-next-line
  }, []);

  return (
    <Body>
      {props.parent && (
        <Item
          onClick={() => {
            if (props.parentHref) {
              history.push(props.parentHref);
            }
          }}
        >
          <FontAwesomeIcon icon="arrow-left" style={{ marginRight: "10px" }} />
          {props.parent}
        </Item>
      )}
      <Title>{props.title}</Title>
      {props.tabs.map((name, index) => (
        <Item
          key={name}
          className={index === selectedIndex ? "selected" : ""}
          onClick={() => {
            setSelectedIndex(index);
            if (props.saveToQueryString) {
              saveToQueryString("tab", props.tabs[index]);
            }
            props.onClick(name, index);
          }}
        >
          {props.displayNames?.[index] || name}
        </Item>
      ))}
    </Body>
  );
};

const saveToQueryString = (key: string, value: string) => {
  const params = new URLSearchParams(window.location.search);
  params.set(key, value);
  let newUrl =
    window.location.protocol + "//" + window.location.host + window.location.pathname + "?" + params.toString();
  window.history.pushState({ path: newUrl }, "", newUrl);
};
