import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { DynamicCondition, DynamicConditionOperatorOptions, DynamicConditionTargetOptions, DynamicConditionTerm, STATUS_VALUES } from "../../../common/api";
import { justConfirm } from "../../../common/components/Modals";
import { arrayReplace, arrayRemove } from "../../../common/features/Utils";

interface ConditionEditorProps {
  condition: DynamicCondition | null;

  // If no condition is returned, then nullify the condition on the parent
  onChange: (condition: DynamicCondition | null) => void;
}
export const DynamicConditionEditor = (props: ConditionEditorProps) => {
  const newTerm: DynamicConditionTerm = {
    target: "STATUS",
    operator: "Greater than",
    comparisonValue: "CANCELLED"
  };

  const addNewTerm = () => {
    props.onChange({ ...props.condition, terms: [...props.condition.terms, newTerm] });
  }

  const [editIndex, setEditIndex] = React.useState(-1);

  if (!props.condition) {
    return <button type="button" className="inline secondary"
      onClick={() => props.onChange({
        logicalOperator: "AND",
        terms: [newTerm]
      })}>
      <FontAwesomeIcon icon="plus" /> Add conditional logic
    </button>
  }

  return (
    <div style={{ margin: "10px 0" }}>
      <h6>Conditions</h6>
      <label>Select grouping</label>
      <select value={props.condition.logicalOperator}
        onChange={e => props.onChange({ ...props.condition, logicalOperator: e.target.value as any })}>
        <option value="OR">OR</option>
        <option value="AND">AND</option>
      </select >
      <span style={{ marginLeft: "20px" }}>
        ({props.condition.logicalOperator === "AND" ? "All of the listed conditions must match." : "Any one or more of the listed conditions must match"})
      </span>

      <div>
        {props.condition.terms.map((term, index) =>
        (<div key={"" + index}>
          <>
            {index === editIndex ? (
              <ConditionTermEditor term={term} onClose={() => setEditIndex(-1)}
                onChange={newTerm => props.onChange({ ...props.condition, terms: arrayReplace(props.condition.terms, newTerm, index) })} />
            ) : (
              <>
                <span style={{ display: "inline-block", width: "250px" }}>{stringifyTerm(term)}</span>
                <button type="button" className="inline secondary" onClick={() => setEditIndex(index)}>
                  <FontAwesomeIcon icon="edit" />
                </button>
                <button type="button" className="inline secondary" onClick={() => {
                  justConfirm(`Are you sure you want to remove term "${stringifyTerm(term)}"?`).then(() => {
                    props.onChange({ ...props.condition, terms: arrayRemove(props.condition.terms, index) });
                    setEditIndex(-1);
                  });
                }}>
                  <FontAwesomeIcon icon="trash" />
                </button>
              </>
            )}
          </>
        </div>
        ))}


        <button type="button" className="inline secondary" onClick={addNewTerm}>
          <FontAwesomeIcon icon="plus" /> New term
        </button>
        <button type="button" className="inline secondary" onClick={() => {
          justConfirm(`Are you sure you want to remove all conditions from this dynamic element?`).then(() => {
            props.onChange(null);
          });
        }}>
          <FontAwesomeIcon icon="trash" /> Clear condition
        </button>
      </div>
    </div>
  );
}


interface TermEditorProps {
  term: DynamicConditionTerm;
  onChange: (term: DynamicConditionTerm) => void;
  onClose: () => void;
}
export const ConditionTermEditor = (props: TermEditorProps) => {
  return (<div style={{ padding: "10px", backgroundColor: "#ECE4E0", borderRadius: "10px" }}>
    <div>
      <label htmlFor="termTarget">Target</label>
      <select id="termTarget" value={props.term.target} onChange={e => props.onChange({ ...props.term, target: e.target.value as any })}>
        {DynamicConditionTargetOptions.map(option => <option key={option} value={option}>{option}</option>)}
      </select>
    </div>
    {props.term.target === "FIELD" && <div>
      <label htmlFor="fieldTarget">Field name</label>
      <input id="fieldTarget" type="text" value={props.term.targetDetails || ""} onChange={e => props.onChange({ ...props.term, targetDetails: e.target.value })} />
    </div>}
    <div>
      <label htmlFor="termOperator">Operator</label>
      <select id="termOperator" value={props.term.operator} onChange={e => props.onChange({ ...props.term, operator: e.target.value as any })}>
        {DynamicConditionOperatorOptions.map(option => <option key={option} value={option}>{option}</option>)}
      </select>
    </div>
    {
      props.term.operator !== "Exists" && <div>
        <label htmlFor="termComparisonValue">Comparison value</label>
        {props.term.target === "STATUS" ? (
          <select id="termComparisonValue" value={props.term.comparisonValue} onChange={e => props.onChange({ ...props.term, comparisonValue: e.target.value })}>
            {STATUS_VALUES.map(val => <option key={val} value={val}>{val}</option>)}
          </select>
        ) : (
          <input type="text" value={props.term.comparisonValue} onChange={e => props.onChange({ ...props.term, comparisonValue: e.target.value })} />
        )}
      </div>
    }
    <div style={{ padding: "10px" }}>
      <button type="button" className="inline secondary" onClick={() => props.onClose()}>
        <FontAwesomeIcon icon="check" /> Done
      </button>
    </div>
  </div>)
}

export const stringifyTerm = (term: DynamicConditionTerm) => {
  let str = "";
  str = term.target;
  if (term.targetDetails) {
    str += `[${term.targetDetails}]`;
  }
  str += " " + term.operator;

  if (term.comparisonValue !== null && term.comparisonValue !== undefined) {
    str += " " + term.comparisonValue;
  }

  return str;
}