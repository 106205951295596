import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { makeRequest, Process } from "../../common/api";
import { SideBar, SideBarPageBody } from "../../common/components/SideBar";
import { LoadingPage } from "../../common/features/Utils";
import { ProcessConfigEditor } from "./ProcessConfigEditor";


export const EditProcessPage = () => {
  const history = useHistory();
  const { processId } = useParams<any>();
  const [tab, setTab] = React.useState<string>("metadata"); 

  const [process, setProcess] = React.useState<Process | null>(null);

  React.useEffect(() => {
    setProcess(null);
    makeRequest(`/api/processConfigBlobs/${processId}`, "GET")
      .then((p) => {
        setProcess(JSON.parse(p.json));
      });
  }, [processId]);

  const tabs = [
    "metadata", "dashboard", "initial application", "form steps", "triggers"
  ];

  if (!process) {
    return <LoadingPage />;
  }

  // onClose, navigate back to the list of processes
  return (
    <SideBarPageBody>
      <SideBar
        title={process.label}
        tabs={tabs}
        saveToQueryString={true}
        onClick={(key: any) => {
          setTab(key);
        }}
        parent="All Processes"
        parentHref="/admin/config?tab=forms+%26+processes"
      />
      <div style={{overflow: "auto", flex: "1 1 100%"}}>
        <ProcessConfigEditor tab={tab} process={process} onSaved={() => null} onClose={() => {
          history.push("/admin/config?tab=forms+%26+processes");
        }} isAdding={false} />
      </div>
    </SideBarPageBody>)
};