import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Trigger } from "../../common/api";
import { BoolInput, TextInput } from "../../common/components/Forms";
import { LoadingIndicator } from "../../common/features/Utils";
import { DynamicConditionEditor, stringifyTerm } from "./common/DynamicConditions";
import { SimpleResourceList } from "./SimpleResourceList";

export const Triggers = () => {
  return (
    <>
      <p>
        Once you create a trigger here, you MUST add it to a Form Process in
        order for it to work. Multiple processes can share the same trigger.
      </p>
      <SimpleResourceList
        title="Your Triggers"
        resourceKey="triggers"
        listItemContent={TriggerListItemContent as any}
        editItemForm={EditTriggerForm as any}
      />
    </>
  );
};

export const TriggerListItemContent = ({ item }: { item: Trigger }) => {
  const term = item.condition?.terms?.[0];
  if (!term) {
    return <div>No terms</div>;
  }

  return (
    <div>
      Runs when {stringifyTerm(term)}
    </div>
  );
};

interface EditTriggerFormProps {
  item: Trigger;
  onSave: (trigger: Trigger) => void;
  onClose: () => void;
}
export const EditTriggerForm = (props: EditTriggerFormProps) => {
  const [item, setItem] = React.useState<Trigger>(
    props.item ||
    ({
      name: "",
      isActive: true,
    } as any)
  );

  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <div>
      <div>
        <TextInput object={item} propName="name" onChange={setItem} disabled={isLoading} />
      </div>
      <div>
        <BoolInput object={item} propName="isActive" onChange={setItem} disabled={isLoading} />
      </div>
      <div>
        <label>Configure Trigger</label>
        <div style={{display: "inline-block", padding: "10px", backgroundColor: "hsla(280, 50%, 40%, 0.25)", borderRadius: "10px"}}>
          <DynamicConditionEditor condition={item.condition}
            onChange={condition => setItem({ ...item, condition: condition })} />
        </div>
      </div>
      <div>
        <label>Action key</label>
        <input type="text" value={item.action?.targetId || ""} disabled={isLoading}
          onChange={e => setItem({ ...item, action: { ...item, targetId: e.target.value } })}
        />
      </div>
      <p>
        Currently, we only support pre-configured actions. Contact support for custom action requests
        and you will be provided a custom action key for use here.
      </p>


      <div style={{ marginTop: "20px" }}>
        <button
          disabled={isLoading}
          type="button"
          onClick={async () => {
            setIsLoading(true);
            await props.onSave(item);
            setIsLoading(false);
          }}
        >
          {isLoading && <LoadingIndicator />}
          Save changes
        </button>
        <button
          disabled={isLoading}
          className="secondary"
          type="button"
          onClick={props.onClose}
        >
          <FontAwesomeIcon icon="times" /> Cancel
        </button>
      </div>
    </div>
  );
};
