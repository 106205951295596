import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { makeRequest, Organization, User } from './common/api';
import styled from "styled-components";
import { UserContext } from './common/UserContext';
import { isUserCreator } from './common/auth';
import {
  Menu,
  MenuItem,
  SubMenu
} from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import { OrgContext } from './common/OrgContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

setTimeout(() => {
  const params = new URLSearchParams(window.location.search);
  const orgIdParam = params.get("organizationId");
  if (orgIdParam) {
    window.localStorage.setItem("organizationId", orgIdParam);
  }
}, 20);

const Header = styled.header`
  overflow-x: auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const HeaderContent = styled.div`
  color: #000;
  font-size: 16px;
  padding: 15px 10px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: end;

  min-width: 550px;

  @media screen and (max-width: 400px) {
    padding-left: 20px;
  }
`;

const NavLink = styled(Link)`
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  font-weight: 500;

  text-decoration: none;
  cursor: pointer;

  margin-right: 20px;
  white-space: no-wrap;
  
  :focus, :hover, .selected {
    color: #B181EA;
  }

  .navLink:active {
    color: #A161DA;
  }
`;

const MainNavLink = styled(NavLink)`
  margin-right: 40px;
  width: auto;
  @media screen and (max-width: 400px) {
    display: none;
  }
`;

const NavImg = styled.img`
  height: 40px;
`;

const LogoutLink = styled.span`
  margin-left: 10px;
  font-size: 12px;
  color: #84C;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const NavBar = () => {
  const userContext = React.useContext(UserContext);
  const user = userContext?.user;

  const logout = async () => {
    await makeRequest("/api/logout", "POST");
    userContext?.logout();
    window.location.reload();
  }


  return (
    <Header>
      <HeaderContent>
      <MainNavLink to="/">
        <NavImg src="/logo.png" />
      </MainNavLink>
      <NavLink to="/apply">Apply</NavLink>
      {isUserCreator(userContext?.user) && (
        <NavLink to="/admin/dashboard">Dashboard</NavLink>
      )}
      {isUserCreator(userContext?.user) && (
        <NavLink to="/admin/config">Config</NavLink>
      )}

      <div style={{ textAlign: "right", flexGrow: 1, display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        {user ? (
          <>
            {(user.isCreator || (user?.orgRoles?.length > 0)) ? <UserDropdown user={user} /> : <span>{user.email}</span>}
            <LogoutLink onClick={logout}>Logout</LogoutLink>
          </>
        ) : (
          <NavLink to="/login">Log In / Sign Up</NavLink>
        )}
      </div>
      </HeaderContent>
    </Header>
  );
};

interface UserDropdownProps {
  user: User | null;
}
export const UserDropdown = (props: UserDropdownProps) => {
  const org = React.useContext(OrgContext);
  const history = useHistory();
  const [organizations, setOrganizations] = React.useState<Organization[] | null>(null);

  const fetchOrganizations = async () => {
    let orgs = organizations || [];
    for (let orgRole of props.user.orgRoles) {
      if (org && org !== "NONE" && orgRole.orgId === org._id) {
        orgs = [...orgs, org];
        setOrganizations(orgs);
      } else {
        const orgItem = await makeRequest(`/api/organizations/${orgRole.orgId}`, "GET");
        orgs = [...orgs, orgItem];
        setOrganizations(orgs);
      }
    }
  };
  React.useEffect(() => {
    if (props.user && !organizations) {
      setOrganizations([]);
      fetchOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizations, props.user]);

  // Loads the current page or homepage of the selected organization
  // using that organization's domain, subdomain or route.
  const openOrg = (orgId: string) => {
    if (org && org !== "NONE" && orgId === org._id) {
      return;
    } else {
      if (window.location.hostname === "localhost") {
        window.localStorage.setItem("organizationId", orgId);
        window.location.href = window.location.origin + window.location.pathname + "?organizationId=" + orgId;
        return;
      }

      const newOrg = organizations.find(o => o._id === orgId);
      if (newOrg?.fullDomain) {
        window.location.href = "https://" + newOrg.fullDomain;
        return;
      }
      if (newOrg?.subdomain) {
        window.location.href = "https://" + newOrg.subdomain + ".dreamstack.app";
        return;
      }

      // localStorage is the location of last resort for selecting an organizationId for localhost or dreamstack root
      window.localStorage.setItem("organizationId", orgId);
      window.location.href = "https://dreamstack.app?organizationId=" + orgId
    }
  };

  // Navigates to the organization creation page on dreamstack
  const createNewOrg = () => {
    history.push("/createOrganization")
  };

  return (
    <Menu menuButton={<span style={{cursor: "pointer"}}>{props.user.email} <FontAwesomeIcon icon="chevron-down" /></span>}
      portal={true} align="end" direction="bottom" position="initial">
      <SubMenu label="Organizations">
        {props.user.orgRoles.map(role => {
          const knownOrgs = [...(organizations || [])];
          if (org && org !== "NONE") {
            knownOrgs.push(org);
          }
          const isCurrent = org && org !== "NONE" && org._id === role.orgId;

          return (<MenuItem key={role.orgId} onClick={() => openOrg(role.orgId)}>
            {isCurrent && <FontAwesomeIcon icon="check" />}
            {knownOrgs.find(o => o._id === role.orgId)?.name} - {role.role}
          </MenuItem>);
        })}
      </SubMenu>
      <MenuItem onClick={createNewOrg}>Create new organization</MenuItem>
    </Menu >
  );
};