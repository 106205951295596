import React from "react";
import { UserContext } from "../UserContext";
import styled from "styled-components";
import { AdminComment, Application, EmailTemplate, getAvailableAdmins, makeRequest, makeUploadRequest, PaymentModel, STATUS_VALUES, User } from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isUserAdmin } from "../auth";
import { justConfirm } from "../components/Modals";
import { arrayRemove, formatDurationRough } from "./Utils";
import { OrgIdContext } from "../OrgContext";
import { DataTable } from "../components/DataTable";
import { TabBar } from "../components/TabBar";

const Wrapper = styled.div`
    background-color: #FEF;
    border: 2px solid #DBD;
    padding: 10px;
`;

interface Props {
    application: Application;
    onApplicationChange: (application: Application) => void;
}
export const AdminPanel = (props: Props) => {
    const user = React.useContext(UserContext)?.user;
    const orgId = React.useContext(OrgIdContext);
    const [currentTab, setCurrentTab] = React.useState<"Admin tools" | "User activity">("Admin tools");
    const [adminComment, setAdminComment] = React.useState<string>("");
    const [assignee, setAssignee] = React.useState<string>("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedPaymentModelId, setSelectedPaymentModelId] = React.useState<string>(props.application.selectedPaymentModelId || "");
    const [paymentModels, setPaymentModels] = React.useState<PaymentModel[] | null>(null);

    const [emailTemplates, setEmailTemplates] = React.useState<EmailTemplate[]>([]);
    React.useEffect(() => {
        makeRequest("/api/emailTemplates", "GET").then(resp => setEmailTemplates(resp));
        makeRequest("/api/paymentModels", "GET").then(models => {
            setPaymentModels(models);
        });
    }, []);
    const [selectedEmail, setSelectedEmail] = React.useState<EmailTemplate | null>();
    const selectEmail = (name: string) => {
        setSelectedEmail(emailTemplates.find(t => t.name === name));
    };
    const sendSelectedEmail = () => {
        if (!selectedEmail || isLoading) {
            return;
        }
        setIsLoading(true);

        const name = selectedEmail.name;
        makeRequest("/api/sendEmailTemplate", "POST", {
            templateId: selectedEmail._id,
            applicationId: props.application._id
        }).then(() => {
            const updatedApplication = { ...props.application };

            updatedApplication?.adminComments?.push({
                message: `Sent mail template '${name}'`,
                postedDate: new Date(),
                authorId: user?._id || "",
                authorEmail: user?.email || "",
            });
            updateApplication(updatedApplication);

            alert("Sent '" + name + "' to " + props.application.email);
        });
        setSelectedEmail(null);
    };

    const rescoreAssessments = () => {
      makeRequest(`/api/grading/applications/${props.application._id}/grade`, "POST").then((response) => {
        alert("Rescored assessment. Refresh the page to see it.");
      }, (err) => {
        alert(`Something went wrong updating the application: '${err.message}'`);
      })
    };

    const [admins, setAdmins] = React.useState<User[] | null>(null);
    React.useEffect(() => {
        if (isUserAdmin(user, orgId)) {
            getAvailableAdmins().then(admins => setAdmins(admins));
        }
    }, [user, orgId]);

    const updateApplication = (updatedApplication: Application) => {
        setIsLoading(true);
        props.onApplicationChange(updatedApplication);
        makeRequest(`/api/applications/${props.application._id}`, "PUT", updatedApplication).then(response => {
            props.onApplicationChange(response);
            setIsLoading(false);
        }, (err) => {
            alert(`Something went wrong updating the application: '${err.message}'`);
            setIsLoading(false);
        });
    }

    const [contractUpload, setContractUpload] = React.useState<any>(null);
    const uploadContract = async () => {
        if (!contractUpload) {
            return;
        }
        setIsLoading(true);
        await makeUploadRequest("application", props.application._id, "contract", contractUpload, orgId);
        const updatedApplication = await makeRequest(`/api/applications/${props.application._id}`, "GET");
        props.onApplicationChange(updatedApplication);
        setIsLoading(false);
    };

    const postComment = () => {
        const updatedApplication = { ...props.application };
        updatedApplication.adminComments = updatedApplication.adminComments || [];
        updatedApplication.adminComments.push({
            message: adminComment,
            authorId: user?._id || "",
            authorEmail: user?.email || "anonymous",
            postedDate: new Date()
        });
        setAdminComment("");
        updateApplication(updatedApplication);
    }

    // Thanks to gensarm for this list of statuses
    const statuses = STATUS_VALUES;
    const [status, setStatus] = React.useState(props.application.status);
    React.useEffect(() => {
        setStatus(props.application.status);
    }, [props.application]);

    const updateStatus = () => {
        const updatedApplication = { ...props.application };
        updatedApplication.status = status;
        updateApplication(updatedApplication);
    }
    const updatePaymentModel = () => {
        if (selectedPaymentModelId) {
            updateApplication({ ...props.application, selectedPaymentModelId })
        }
    }
    const updateAssignee = () => {
        const updatedApplication = { ...props.application };
        const admin = admins?.find(a => a.email === assignee);
        updatedApplication.adminAssignee = admin ? {
            userId: admin._id,
            userEmail: admin.email
        } : undefined;
        updateApplication(updatedApplication);
    }

    const deleteComment = async (comment: AdminComment, index: number) => {
        await justConfirm("Are you sure you want to delete your comment " + comment.message + "?");
        const updatedApplication = { ...props.application };
        updatedApplication.adminComments = arrayRemove(updatedApplication.adminComments, index);
        updateApplication(updatedApplication);
    };

    /*
    // We do not currently support tracking payments on this CRM
    const initializeAccount = async () => {
        setIsLoading(true);
        makeRequest("/api/openPaymentAccount", "POST", {
            userId: props.application.userId,
            applicationId: props.application._id,
            organizationId: props.application.organizationId,
            // paymentModelId: optionally used to override the selected application payment model,
            // or if there is no payment model selected on the application
        }).then((account: PaymentAccount) => {
            alert("Payment account started. Please refresh the page to see changes.");
        }).finally(() => {
            setIsLoading(false);
        });
    };
    */

    if (!isUserAdmin(user, orgId)) {
        return null;
    }

    
    const latestEntry = props.application.userActivity?.activityEntries?.length > 0 && props.application.userActivity?.activityEntries?.[props.application.userActivity?.activityEntries?.length - 1];
    const millis = latestEntry ? Date.now() - new Date(latestEntry.timestamp).getTime() : 0;
    const lastSeenText = latestEntry ? formatDurationRough(millis) + " ago" : "";


    return (
        <Wrapper>
            <TabBar tabs={["Admin tools", "User activity"]} onClick={(t) => setCurrentTab(t as any)} saveToQueryString={false} />
            {currentTab === "Admin tools" && (
            <>
                <h4>Admin tools</h4>
                <div>
                    {isLoading && <div>Loading...<FontAwesomeIcon icon="circle-notch" spin={true} size="1x" /></div>}
                </div>
                <h5>Status</h5>
                <div>Current status: {props.application.status}</div>
                <div>Last seen: {lastSeenText}</div>
                <label style={{ width: "auto" }}>
                    Select new status:
                    <select value={status} onChange={e => setStatus(e.target.value)}>
                        {statuses.map(s => <option key={s} value={s}>{s}</option>)}
                    </select>
                </label>
                <button disabled={isLoading} onClick={updateStatus} className="inline secondary">Confirm</button>
                <br />

                <h5>Payment model</h5>
                <label style={{ width: "auto" }}>
                    Select plan:
                    <select value={selectedPaymentModelId} onChange={e => setSelectedPaymentModelId(e.target.value)}>
                        <option value=""></option>
                        {paymentModels?.map(model => {
                            return <option key={model._id} value={model._id}>{model.name}</option>;
                        })}
                    </select>
                </label>
                <button disabled={isLoading} onClick={updatePaymentModel} className="inline secondary">Confirm</button>

                <h5>Assignee</h5>
                <div>
                    {props.application.adminAssignee ?
                        <div>Assigned to <b>{props.application.adminAssignee.userEmail}</b></div> :
                        <div>Unassigned</div>}

                    {admins && <div><label style={{ width: "auto" }}>
                        Assign to:
                                <select value={assignee} onChange={e => setAssignee(e.target.value)}>
                            {admins.map(a => <option key={a.email} value={a.email}>{a.email}</option>)}
                            <option value="">UNASSIGNED</option>
                        </select>
                    </label>
                        <button disabled={isLoading} onClick={updateAssignee} className="inline secondary">Confirm</button>
                    </div>}
                </div>

                <h5>Assessments</h5>
                <div>
                    <button disabled={isLoading} onClick={rescoreAssessments} className="inline secondary">Rescore assessment(s)</button>
                </div>

                <h5>Comments</h5>
                <div>
                    {props.application.adminComments?.map((c, i) => (
                        <div key={"" + i} style={{ margin: "10px 5px" }}>
                            <div style={{ color: "#666" }}>
                                Posted by <b>{c.authorEmail}</b> on <i>{c.postedDate?.toLocaleString()} (local time)</i>
                                {c.authorEmail === user?.email && <span>
                                    <button className="secondary inline" onClick={() => deleteComment(c, i)}>Delete</button>
                                </span>}
                            </div>
                            <div>{c.message}</div>
                        </div>))
                    }
                </div>
                <div>
                    <b>Commenting as {user!.email}</b>
                    <br />
                    <form>
                        <input disabled={isLoading} type="text" value={adminComment} onChange={e => setAdminComment(e.target.value)} />
                        <button className="secondary inline" style={{ marginLeft: "20px" }} disabled={isLoading} onClick={postComment}>
                            Submit comment
                        </button>
                    </form>
                    <br />
                </div>

                <h5>Contract</h5>
                <div>
                    <label htmlFor="contract">Completed Contract</label>
                    <div style={{ display: "inline-block" }}>
                        <input id="contract" type="file" onChange={(e) => setContractUpload(e.target?.files?.[0])} />
                        <button className="inline" onClick={uploadContract} disabled={isLoading || !contractUpload}>
                            {isLoading && <FontAwesomeIcon icon="circle-notch" spin={true} />}
                        Upload
                        </button>
                    </div>
                </div>

                <h5>Send an Email</h5>
                <div>
                    <div>
                        <label style={{ width: "auto" }}>
                            Template:
                                <select value={selectedEmail?.name || ""} onChange={e => selectEmail(e.target.value)}>
                                <option value="none">None</option>
                                {emailTemplates.map(a => <option key={a.name} value={a.name}>{a.name}</option>)}
                            </select>
                        </label>

                        <button disabled={isLoading} onClick={sendSelectedEmail} className="inline secondary">Send</button>
                    </div>
                    {selectedEmail && (<div>
                        <h5>{selectedEmail.subject}</h5>
                        <p>{selectedEmail.body}</p>
                    </div>)}
                </div>
                
                <h5>Tracking links</h5>
                {!props.application?.tracking && <div>No tracking data attached</div>}
                {
                props.application?.tracking && <div>
                    <div>Source: {props.application.tracking.source}</div>
                    <div>Medium: {props.application.tracking.medium}</div>
                    <div>Campaign: {props.application.tracking.campaign}</div>
                    <div>Term: {props.application.tracking.term}</div>
                    <div>Content: {props.application.tracking.content}</div>
                </div>
                }
            </>)}
            {currentTab === "User activity" && (
                <div>
                    <h4>User activity</h4>
                    {props.application.userActivity?.activityEntries ?
                    <DataTable data={props.application.userActivity.activityEntries} columns={[
                        {label: "Action", key: "action"},
                        {label: "Details", key: "actionDetails"},
                        {label: "Time", key: "timestamp", type: "timestamp"}
                    ]} /> 
                    :
                    <div>No activity recorded. Note that activity tracking did not begin until Nov 12, 2021</div> }
                </div>
                )}

            {/*
            <h5>Initialize Payment Account</h5>
            <p>Start a new payment account using the selected payment model for the student.</p>
            <button disabled={isLoading} onClick={initializeAccount}>Start new Account: '{selectedPaymentModel.name}'</button>*/}
        </Wrapper >
    );
}