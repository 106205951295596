import React from "react";
import { useParams, Link } from "react-router-dom";
import styled from "styled-components";
import {
  Application,
  Assessment,
  Batch,
  DynamicIntegration,
  makeRequest,
  PaymentModel,
  Process,
  ProcessStep,
  Solution,
} from "../common/api";
import {
  AttachmentLink,
  formatMinutes,
  StatusIcon,
  Markdown,
  LoadingIndicator,
} from "../common/features/Utils";
import { Card } from "../common/components/Card";
import { FixedContainer, PageBody } from "../common/components/PageBody";
import { LoadingPage } from "../common/features/Utils";
import { AdminPanel } from "../common/features/AdminPanel";
import { isUserAdmin, isUserViewer } from "../common/auth";
import { UserContext } from "../common/UserContext";
import { DynamicForm } from "../common/features/DynamicForm";
import DOMPurify from "dompurify";
import marked from "marked";
import { checkCondition } from "../common/features/DynamicConditions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getPaymentModelDescription } from "../common/features/EditPaymentModelForm";
import { trackAction } from "../common/features/UserTracking";

/**
 * User-facing application details page. Primary location to
 * update and check the status of an application.
 */
export const ApplicationDetailsPage = () => {
  const user = React.useContext(UserContext)?.user;

  const { applicationId } = useParams<any>();

  const [application, setApplication] = React.useState<Application | null>(
    null
  );
  const [batch, setBatch] = React.useState<Batch | null>(null);
  const [process, setProcess] = React.useState<Process | null>();

  const [openStep, setOpenStep] = React.useState<ProcessStep | null>(null);

  const [assessments, setAssessments] = React.useState<Assessment[] | null>(
    null
  );

  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    makeRequest("/api/assessments", "GET").then((items) =>
      setAssessments(items)
    );
  }, []);

  const [solutions, setSolutions] = React.useState<Solution[] | null>(null);

  // Fetch Application and Batch
  React.useEffect(() => {
    makeRequest(`/api/applications/${applicationId}`, "GET").then(
      (app: Application) => {
        setApplication(app);
        trackAction(applicationId, "VIEW_APPLICATION");

        makeRequest(`/api/solutions?appId=${app._id}`, "GET").then(
          (slns: Solution[]) => {
            setSolutions(slns);
          }
        );
        makeRequest(`/api/batches/${app.batchId}`, "GET").then((newBatch) => {
          setBatch(newBatch);
          makeRequest(
            `/api/processConfigBlobs/${newBatch.processId}`,
            "GET"
          ).then((p) => {
            setProcess(JSON.parse(p.json));
          });
        });
      },
      () => {
        setErrorMessage("Could not load application. Are you logged in?");
      }
    );
  }, [applicationId]);

  const isViewedByAdminViewer =
    isUserViewer(user, application?.organizationId) &&
    !isUserAdmin(user, application?.organizationId) &&
    application?.userId !== user?._id;
  const isReadOnly =
    !isUserAdmin(user, application?.organizationId) &&
    (batch?.status === "Complete" || isViewedByAdminViewer);

  if (errorMessage) {
    return <PageBody>{errorMessage}</PageBody>;
  }

  if (!application || !batch) {
    return <LoadingPage />;
  }

  return (
    <PageBody>
      <FixedContainer>
        {!openStep && <h2>{batch.name}</h2>}
        {batch.status === "Complete" ? (
          <h4 style={{ color: "red" }}>
            This course has started. Applications may not be changed anymore.
          </h4>
        ) : (
          application?.status === "CANCELLED" && (
            <h4 style={{ color: "red" }}>
              This application is not active. It could be a duplicate or
              past-deadline. Check the home page for open applications or start
              a new one to join the latest batch of students.
            </h4>
          )
        )}
        {!openStep && <Markdown content={batch.description} />}
        {/*!openStep && (<div style={{ marginBottom: "10px" }}><Link to={"/batch/" + batch._id}><b>See additional details.</b></Link></div>)*/}
        {openStep && <h5>{batch.name}</h5>}
        {openStep && (
          <EditStepForm
            step={openStep}
            application={application}
            batch={batch}
            assessments={assessments}
            solutions={solutions}
            isReadOnly={!!isReadOnly}
            onComplete={(newApplication) => {
              if (
                JSON.stringify(application) !== JSON.stringify(newApplication)
              ) {
                trackAction(
                  application._id,
                  "SAVE_APPLICATION_STEP",
                  openStep.title
                );
              }
              setApplication(newApplication);
              setOpenStep(null);
            }}
            onClose={() => setOpenStep(null)}
          />
        )}
        {!openStep && (
          <>
            <div>
              <div>
                Application status: <b>{application.status}</b>
              </div>
              <div>
                Name: {application.firstName} {application.lastName}
              </div>
              <div>Email: {application.email}</div>
              <div>Phone: {application.phoneNumber}</div>
              <div>
                Resume:{" "}
                <span>
                  {application.resume ? (
                    <AttachmentLink file={application.resume} />
                  ) : (
                    "Not uploaded."
                  )}
                </span>
              </div>
              {application.completedContract && (
                <div>
                  Contract:{" "}
                  <AttachmentLink file={application.completedContract} />
                </div>
              )}
            </div>
            <div>
              {process?.applicationDetailsSteps.map((step) => (
                <ApplicationStep
                  key={step.title}
                  step={step}
                  application={application}
                  onClick={() => setOpenStep(step)}
                  batch={batch}
                  solutions={solutions}
                />
              ))}
            </div>
          </>
        )}
        {isUserAdmin(user, application.organizationId) && (
          <AdminPanel
            application={application}
            onApplicationChange={setApplication}
          />
        )}
      </FixedContainer>
    </PageBody>
  );
};

const StepCard = styled.div`
  display: flex;
  border-radius: 6px;
  border: 1px solid #999;
  box-shadow: 0px 0px 3px 3px rgba(0, 0, 0, 0.1);
  margin: 20px;
  padding: 20px;
  width: 500px;
  cursor: pointer;

  max-width: calc(100% - 20px);
`;

const CardTitle = styled.div`
  font-size: 22px;

  @media screen and (max-width: 400px) {
    font-size: 14px;
  }
`;
const CardDetails = styled.div`
  color: #555;

  @media screen and (max-width: 400px) {
    font-size: 10px;
  }
`;

interface StepProps {
  step: ProcessStep;
  application: Application;
  batch: Batch;
  solutions: Solution[] | null;
  onClick: () => void;
}
const ApplicationStep = (props: StepProps) => {
  if (props.step.visibilityCondition) {
    if (
      !checkCondition(
        props.step.visibilityCondition,
        props.application,
        props.solutions
      )
    ) {
      return null;
    }
  }

  const isComplete = checkCompletion(
    props.step,
    props.application,
    props.solutions,
    props.batch
  );
  return (
    <StepCard onClick={props.onClick}>
      <div style={{ marginRight: "20px" }}>
        <StatusIcon status={isComplete ? "complete" : "new"} />
      </div>
      {props.step.configuration ? (
        // TODO: Reduce this to only support dynamic configuration
        <div>
          <CardTitle>
            {props.step.title || props.step.configuration.title}
          </CardTitle>
          <CardDetails>
            {props.step.subtitle ||
              props.step.configuration.fields.map((f) => f.label).join(", ")}
          </CardDetails>
        </div>
      ) : (
        <div>
          <CardTitle>{props.step.title}</CardTitle>
          <CardDetails>{props.step.subtitle}</CardDetails>
        </div>
      )}
    </StepCard>
  );
};

const checkCompletion = (
  step: ProcessStep,
  application: Application,
  solutions: Solution[] | null,
  batch: Batch
) => {
  let completed = true;
  if (step.completionCondition) {
    return checkCondition(step.completionCondition, application, solutions);
  }

  if (
    (!step.configuration || step.configuration?.fields?.length === 0) &&
    !step.assessmentIds &&
    !step.availablePaymentModels
  ) {
    return false;
  }
  for (let field of step.configuration?.fields || []) {
    completed = completed && !!(application as any)[field.name];
  }

  if (step.assessmentIds) {
    completed =
      completed &&
      !!step.assessmentIds.every((assId) => {
        return !!solutions?.find(
          (sln) => sln.assessmentId === assId && !!sln.submittedAt
        );
      });
  }

  if (step.availablePaymentModels) {
    completed = completed && !!application.selectedPaymentModelId;
  }

  return completed;
};

interface IntegrationProps {
  integration: DynamicIntegration;
  application: Application;
  onApplicationChange: (application: Application) => void;
}
/* 

<!-- Calendly link widget begin -->
<link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet">
<script src="https://assets.calendly.com/assets/external/widget.js" type="text/javascript" async></script>

<!-- Calendly link widget end -->

*/
declare var Calendly;
export class CalendlyIntegration extends React.Component<IntegrationProps, {}> {
  componentDidMount() {
    const existingScript = document.getElementById("calendlyWidgetScript");
    if (!existingScript) {
      const newScript = document.createElement("script");
      newScript.id = "calendlyWidgetScript";
      newScript.type = "text/javascript";
      newScript.src = "https://assets.calendly.com/assets/external/widget.js";
      document.body.appendChild(newScript);

      const newStyle = document.createElement("link");
      newStyle.href = "https://assets.calendly.com/assets/external/widget.css";
      newStyle.rel = "stylesheet";
      document.body.appendChild(newStyle);
    }

    window.addEventListener("message", this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener("message", this.handleMessage);
  }

  handleMessage = (event: any) => {
    // TODO: Placeholder
    if (event.data.event === "calendly.event_scheduled") {
      const eventUri = event.data.payload.event.uri; // "https://api.calendly.com/scheduled_events/6ff79a08-0398-480c-82d8-6968ea35073c"
      const inviteeUri = event.data.payload.invitee.uri; // "https://api.calendly.com/scheduled_events/6ff79a08-0398-480c-82d8-6968ea35073c/invitees/0a1d433b-7a30-4d27-a69c-884c21f39abb"

      makeRequest("/api/eventScheduled", "POST", {
        applicationId: this.props.application._id,
        eventUri: eventUri,
        intiteeUri: inviteeUri,
      }).then((response) => {
        trackAction(this.props.application._id, "SCHEDULED_INTERVIEW");
        this.props.onApplicationChange(response);
      });
    }
  };

  render() {
    if (this.props.application.calendlyEventUri) {
      return (
        <div>
          It looks like you've already scheduled your interview! Check your
          email inbox for details.
          {this.props.application.calendlyJoinUrl && (
            <div style={{ margin: "20px 0" }}>
              Your interview starts at{" "}
              {new Date(
                this.props.application.calendlyStartTime
              ).toLocaleString()}
              .
              <br />
              <br />
              You can join using this link:{" "}
              <a href={this.props.application.calendlyJoinUrl}>
                {this.props.application.calendlyJoinUrl}
              </a>
            </div>
          )}
        </div>
      );
    }

    return (
      <div style={{ margin: "40px 0" }}>
        <button
          className="secondary"
          onClick={() => {
            Calendly.initPopupWidget({
              url: this.props.integration.url,
              prefill: {
                name:
                  (this.props.application.firstName || "") +
                  " " +
                  (this.props.application.lastName || ""),
                email: this.props.application.email,
              },
            });
            return false;
          }}
        >
          Schedule interview
        </button>
      </div>
    );
  }
}

interface EditStepProps {
  step: ProcessStep;
  application: Application;
  batch: Batch;
  assessments: Assessment[] | null;
  solutions: Solution[] | null;
  isReadOnly: boolean;
  // Called after the application update has successfully been PUT on the backend.
  onComplete: (updatedApplication: Application) => void;
  onClose: () => void;
}
const EditStepForm = (props: EditStepProps) => {
  const { isReadOnly } = props;
  const [hasTrackedView, setHasTrackedView] = React.useState(false);

  React.useEffect(() => {
    if (props.application && props.step && !hasTrackedView) {
      setHasTrackedView(true);
      trackAction(
        props.application._id,
        "VIEW_APPLICATION_STEP",
        props.step.title
      );
    }
  }, [props.application, props.step, hasTrackedView]);

  if (props.step.content) {
    return (
      <div>
        <b>{props.step.title}</b>

        {props.step.content.map((item) => {
          if (
            item.condition &&
            !checkCondition(item.condition, props.application, props.solutions)
          ) {
            return null;
          }
          const renderedContent = (
            <p
              dangerouslySetInnerHTML={{
                __html: DOMPurify.sanitize(marked(item.markdown || "")),
              }}
            ></p>
          );

          let integrationContent = null;
          if (item.integrations) {
            integrationContent = (
              <div>
                {item.integrations.map(
                  (i) =>
                    i.type === "Calendly" && (
                      <CalendlyIntegration
                        integration={i}
                        application={props.application}
                        onApplicationChange={(application) =>
                          props.onComplete(application)
                        }
                      />
                    )
                )}
              </div>
            );
          }

          return (
            <div>
              {renderedContent}
              {integrationContent}
            </div>
          );
        })}
        <div style={{ marginTop: "20px" }}>
          <button onClick={props.onClose}>Done</button>
        </div>
      </div>
    );
  }

  if (props.step.configuration) {
    return (
      <DynamicForm
        configuration={props.step.configuration}
        disabled={isReadOnly}
        initialEntry={props.application}
        onSubmitted={(updatedEntry) => props.onComplete(updatedEntry)}
        onClose={props.onClose}
      />
    );
  }

  if (props.step.availablePaymentModels) {
    return (
      <PaymentPlanForm
        step={props.step}
        application={props.application}
        onSaved={(updatedEntry) => {
          props.onComplete(updatedEntry);
        }}
        onClose={props.onClose}
      />
    );
  }

  return (
    <div>
      <b>{props.step.title}</b>
      <div>
        {props.step.assessmentIds && (
          <div>
            <div style={{ margin: "20px" }}>
              This application process requires you to complete{" "}
              <b>{props.step.assessmentIds?.length || 0}</b> assessments.
            </div>
            <div>
              {props.step.assessmentIds?.map((assId, index) => {
                const assessment = props.assessments?.find(
                  (ass) => ass._id === assId
                );
                const solution = props.solutions?.find(
                  (s) => s.assessmentId === assId
                );
                const isSolutionComplete = !!(
                  solution && solution?.submittedAt
                );

                return (
                  <Link
                    to={`/applications/${props.application._id}/assessments/${assId}`}
                    key={assId}
                  >
                    <Card
                      key={assId}
                      style={{
                        width: "400px",
                        display: "flex",
                        color: "black",
                      }}
                    >
                      <div style={{ marginRight: "20px" }}>
                        <StatusIcon
                          status={
                            !!solution
                              ? isSolutionComplete
                                ? "complete"
                                : "active"
                              : "new"
                          }
                        />
                      </div>
                      <div>
                        <b>{assessment?.name || "Assessment " + index}</b>
                        {assessment?.timeLimitMinutes && (
                          <>
                            <br />
                            <br />
                            <p style={{ fontSize: "14px" }}>
                              Duration:{" "}
                              {formatMinutes(assessment!.timeLimitMinutes)}
                            </p>
                          </>
                        )}
                      </div>
                    </Card>
                  </Link>
                );
              })}
            </div>
          </div>
        )}

        <div style={{ marginTop: "20px" }}>
          <button onClick={props.onClose}>Done</button>
        </div>
      </div>
    </div>
  );
};

interface PaymentPlanFormProps {
  step: ProcessStep;
  application: Application;
  onSaved: (updatedApplication: Application) => void;
  onClose: () => void;
}
const PaymentPlanForm = (props: PaymentPlanFormProps) => {
  const [selectedModelId, setSelectedModelId] = React.useState(
    props.application.selectedPaymentModelId || ""
  );
  const [paymentModels, setPaymentModels] = React.useState<
    PaymentModel[] | null
  >(null);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");

  React.useEffect(() => {
    makeRequest("/api/paymentModels", "GET").then(setPaymentModels);
  }, []);

  const selectedModel =
    paymentModels?.find((m) => m._id === selectedModelId) || null;
  return (
    <div>
      <h4>Select your preferred payment plan:</h4>
      <p>
        Choose the plan that best fits your needs. This can still be changed up
        until you've made your first payment.
      </p>
      {paymentModels === null && <LoadingIndicator size="4x" />}
      {paymentModels !== null && (
        <div>
          <label>Plan</label>
          <select
            value={selectedModelId}
            onChange={(e) => setSelectedModelId(e.target.value)}
          >
            <option value=""></option>
            {props.step.availablePaymentModels?.map((modelId) => {
              const model = paymentModels.find((m) => m._id === modelId);
              if (model) {
                return (
                  <option key={model._id} value={model._id}>
                    {model.name}
                  </option>
                );
              }
              return null;
            })}
          </select>
          <br />
          {selectedModel && (
            <div>
              <h4>{selectedModel.name}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(
                    marked(selectedModel.details || "")
                  ),
                }}
              ></div>
              <br />
              <p>{getPaymentModelDescription(selectedModel)}</p>
            </div>
          )}

          {errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
          <button
            type="button"
            style={{ marginTop: "10px" }}
            onClick={() => {
              setIsSubmitting(true);
              const updatedApplication = {
                ...props.application,
                selectedPaymentModelId: selectedModelId,
              };
              makeRequest(
                `/api/applications/${props.application._id}`,
                "PUT",
                updatedApplication
              ).then(
                () => {
                  setIsSubmitting(false);
                  props.onSaved(updatedApplication);
                },
                (err) => {
                  setIsSubmitting(false);
                  setErrorMessage(err.message);
                }
              );
            }}
            disabled={isSubmitting}
          >
            {isSubmitting && (
              <FontAwesomeIcon icon="circle-notch" spin={true} />
            )}
            Save
          </button>
          {props.onClose && (
            <button
              className="secondary"
              style={{ marginLeft: "20px" }}
              onClick={props.onClose}
              disabled={isSubmitting}
            >
              Cancel
            </button>
          )}
        </div>
      )}
    </div>
  );
};
