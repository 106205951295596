import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import DOMPurify from 'dompurify';
import marked from 'marked';
import styled from "styled-components";
import { Application, Assessment, makeRequest, makeUploadRequest, Solution } from "../api";
import { justConfirm } from "../components/Modals";
import { AttachmentLink, LoadingPage, Pre, Required } from "./Utils";
import { Link } from "react-router-dom";
import { trackPurchaseEvent } from "./Analytics";
import { trackAction } from "./UserTracking";

const OptionGrid = styled.div`
  /*display: grid;
  grid-template-columns: repeat(auto-fill, 350px);
  column-gap: 80px;
  row-gap: 40px;
  justify-items: center;*/

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  margin: 20px 0;
`;

const Option = styled.div`
  & label {
    width: 350px;
    padding: 10px;
    text-align: left;
    background-color: #EBEAEC;
    margin: 20px;
    border-radius: 5px;
    font-weight: 400;
    display: flex;
  }

  & label.selectedOption {
    background-color: #9D9;
  }
`;

const NavButton = styled.button`
  margin: 10px 40px 30px 40px !important;
  width: 80px;
`;

interface Props {
  application: Application;
  assessment: Assessment;
  onClose: () => void;
}
export const TakeAssessment = (props: Props) => {
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [solution, setSolution] = React.useState<Solution | null>(null);
  const [isActive, setIsActive] = React.useState(false); // True if the assessment has been started
  const [isLoading, setIsLoading] = React.useState(true);


  React.useEffect(() => {
    setIsLoading(true);
    makeRequest(`/api/solutions?appId=${props.application._id}&ignoreOutdated=true`, "GET").then((newSolutions: Solution[]) => {
      const currentSolution = newSolutions.find(sln => sln.assessmentId === props.assessment._id);

      setSolution(currentSolution || null);
      setIsActive(!!currentSolution && !currentSolution.submission);
      setIsLoading(false);
    });
  }, [props.assessment, props.application]);

  const startAssessment = async () => {
    setIsSubmitting(true);
    const newSolution = await makeRequest("/api/solutions", "POST", {
      assessmentId: props.assessment._id,
      applicationId: props.application._id
    });
    trackAction(props.application._id, "STARTED_ASSESSMENT", props.assessment.name);
    setIsSubmitting(false);
    setSolution(newSolution);
    setIsActive(true);
  };

  const [submissionUpload, setSubmissionUpload] = React.useState<any>(null);

  const submitUploadSolution = async () => {
    if (!solution) {
      return;
    }
    setIsSubmitting(true);
    await makeUploadRequest("solution", solution._id, "submission", submissionUpload, solution.organizationId);
    const updatedSolution = await makeRequest(`/api/solutions/${solution._id}`, "GET");
    setSolution(updatedSolution);
    setIsSubmitting(false);
    trackAction(props.application._id, "SUBMITTED_ASSESSMENT", props.assessment.name);

    trackPurchaseEvent("PHP", 499);
  };

  const onSolutionChanged = (solution: Solution) => {
    setSolution(solution);
    if (!solution) {
      setIsActive(false);
    }
  }

  if (isLoading) {
    return <LoadingPage />;
  }

  return (
    <div style={{ textAlign: "center" }}>
      <h3>
        {props.assessment.name}
        {/* TODO: writing to props.solution  */}
        {solution?.submittedAt && <span style={{ color: "#4B4", marginLeft: "20px" }}>Complete!</span>}
      </h3>
      <h4>Details</h4>
      <Pre dangerouslySetInnerHTML={{ __html: props.assessment.description ? DOMPurify.sanitize(marked(props.assessment.description || "")) : "" }}></Pre>

      {props.assessment.type === "external" && <>
        <h4>External Assessment</h4>
        <p>
          This assessment must be completed on a different website. Follow the link
          below and follow the instructions on the site. After you fully complete
          the assessment, return to this page and verify that the status has been
          marked complete.
        </p>
        <br /><br />
        <h4>Go here:</h4>
        <p style={{marginBottom: "50px"}}>
          <a href={props.assessment.externalAssessmentUrl} target="_blank" rel="noopener noreferrer">
            {props.assessment.externalAssessmentUrl}
          </a>
        </p>
      </>}

      {solution && (
        <div>
          {props.assessment.instructions && (
            <div>
              <h4>Instructions</h4>
              <Pre dangerouslySetInnerHTML={{ __html: props.assessment.instructions ? DOMPurify.sanitize(marked(props.assessment.instructions || "")) : "" }}></Pre>
            </div>
          )}

          {props.assessment.attachment && (<div>
            <h5>Downloads</h5>
            <AttachmentLink file={props.assessment.attachment} />
          </div>)}

          {solution && props.assessment.type === "project" && !solution.submission && (<div>
            <h4>Submission</h4>
            <div>
              <label htmlFor="submission">Your response:<Required /></label>
              <div id="submission" style={{ display: "inline-block" }}>
                <input type="file" onChange={(e) => setSubmissionUpload(e.target?.files?.[0])} />
              </div>
              <div style={{ marginTop: "20px" }}>
                <button onClick={submitUploadSolution} disabled={isSubmitting}>
                  {isSubmitting && <FontAwesomeIcon icon="circle-notch" spin={true} />}
                  Submit
                </button>
              </div>
            </div>
          </div>)}
          {solution?.submission && (
            <div>
              <div>
                You submitted: <AttachmentLink file={solution.submission} />
              </div>
              <Link to={`/application/${props.application._id}`}><button>Done</button></Link>
            </div>
          )}

          {solution && solution.externalDetailsUrl && <>
            <h4>Results</h4>
            <p>
              <a href={solution.externalDetailsUrl} target="_blank" rel="noopener noreferrer">
                {solution.externalDetailsUrl}
              </a>
            </p>
          </>}

          {solution && props.assessment.type === "quiz" && (
            <TakeQuizAssessment assessment={props.assessment} applicationId={props.application._id}
              solution={solution} onSolutionChanged={onSolutionChanged} />
          )}
        </div>
      )}

      {props.assessment.type !== "external" && !isActive && !solution && (<div>
        <button onClick={startAssessment}>
          Start {props.assessment.type}
        </button>
      </div>)
      }

      <div>
        <button className="secondary" onClick={props.onClose}
          style={{ marginLeft: "0", marginTop: "20px" }}>
          Close
        </button>
      </div>

    </div>
  );
}

interface QuizProps {
  assessment: Assessment;
  solution: Solution;
  applicationId: string;
  onSolutionChanged: (solution: Solution) => void;
}
const TakeQuizAssessment = (props: QuizProps) => {
  const [index, setIndex] = React.useState(0);
  const [hasUnsavedChanged, setHasUnsavedChanges] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const question = props.assessment.questions[index];
  const [solution, setSolution] = React.useState<Solution>(props.solution);
  const [answer, setAnswer] = React.useState<{ value: any }>(props.solution.answers[index] || { value: "" });

  const saveIfChanged = () => {
    if (hasUnsavedChanged && !solution.submittedAt) {
      setHasUnsavedChanges(false);
      makeRequest(`/api/solutions/${solution._id}`, "PUT", solution);
    }
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await justConfirm("Are you sure you want to submit? You cannot change your answers after submitting.");
      // TODO: Writing to props.solution doesn't seem sufficient to update the UI
      solution.submittedAt = new Date();
      setSolution({ ...solution });
      const updatedSolution = await makeRequest(`/api/solutions/${solution._id}`, "PUT", solution);
      if (updatedSolution.score && updatedSolution.score >= 50) {
        trackPurchaseEvent("PHP", updatedSolution.score * 10);
      }
      trackAction(props.applicationId, "SUBMITTED_ASSESSMENT", props.assessment.name);
    } catch (ex) {
      alert(ex.message);
    }
    setIsLoading(false);
  };

  const onClickRetake = async () => {
    if (!solution) {
      return;
    }

    setIsLoading(true);

    solution.isRetaken = true;
    try {
      await makeRequest(`/api/solutions/${solution._id}`, "PUT", solution);
      props.onSolutionChanged(null);
      setSolution(null);
    } catch (ex) {

    }
    setIsLoading(false);
  };

  return (<div>
    <h3>Question #{index + 1}</h3>
    <div style={{ margin: "20px", fontSize: "18px" }}>
      {question.prompt}
    </div>
    {question.imageUrl && <div style={{ padding: "10px" }}>
      <img src={question.imageUrl} alt="Contains required details to solve this question. If you are need assistance solving this problem, please contact the organization directly." />
    </div>}
    {question.details &&
      <div
        style={{ textAlign: "left", maxWidth: "70%", margin: "10px auto" }}
        dangerouslySetInnerHTML={{ __html: question.details ? DOMPurify.sanitize(marked(question.details || "")) : "" }}></div>}
    <OptionGrid>
      {question.options.map((o, i) => (
        <Option key={`option${index}-${i}`}>
          <label className={answer && answer.value === o.value ? "selectedOption" : ""}>
            <input
              type="radio"
              name={`question${index}`}
              value={o.value}
              checked={answer && answer.value === o.value}
              disabled={isLoading || !!solution.submittedAt}
              onChange={(e) => {
                if (e.target.checked) {
                  setAnswer({ value: o.value });
                  solution.answers[index] = { value: o.value };
                  setHasUnsavedChanges(true);
                }
              }} />

            {o.value}
          </label>
        </Option>
      ))}
    </OptionGrid>

    <div style={{ margin: "20px 10px 10px 10px" }}>
      <NavButton className="secondary"
        disabled={isLoading || index <= 0}
        onClick={() => {
          setIndex(index - 1);
          setAnswer(solution.answers[index - 1]);
          saveIfChanged();
        }}>&lt; Prev</NavButton>
      <NavButton className="secondary"
        disabled={isLoading || index >= props.assessment.questions.length - 1}
        onClick={() => {
          setIndex(index + 1);
          saveIfChanged();
          setAnswer(solution.answers[index + 1]);
        }}>Next &gt;</NavButton>
    </div>

    <div>
      {
        !solution.submittedAt &&
          solution.answers.filter(a => !!a).reduce((p, c) => p + (c.value ? 1 : 0), 0) >= props.assessment.questions.length ? (
          <button disabled={isLoading} onClick={onSubmit}>Submit</button>
        ) : (
          <p>Answer every question to enable the Submit button.</p>
        )
      }
    </div>

    <div>
      {solution.submittedAt && (
        <div>
          <FontAwesomeIcon style={{ color: "#4B4" }} icon="check-circle" size="4x" />
          This assessment has been completed. Please consult with your application advisor before re-taking
          the assessment. Normally only your initial submission will be counted.
          <br /><br />

          <button onClick={() => onClickRetake()}>Retake</button>
        </div>
      )}
    </div>
  </div>);
}