import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { isUserCreator } from "../common/auth";
import { FixedContainer, PageBody } from "../common/components/PageBody";
import { getFileDownloadUrl, LoadingIndicator } from "../common/features/Utils";
import { YourApplications } from "../common/features/YourApplications";
import { OrgContext } from "../common/OrgContext";
import { UserContext } from "../common/UserContext";
import DOMPurify from "dompurify";
import marked from 'marked';

const StepsList = styled.ol`
  margin-top: 10px;

  & li {
    margin: 8px 0;
  }
`;

export const HomePage = () => {
  const user = React.useContext(UserContext)?.user;
  const org = React.useContext(OrgContext);

  return (
    <PageBody>
      <FixedContainer>
        <YourApplications />
        {!org && <div style={{ color: "#BBB", textAlign: "center", padding: "100px" }}>
          <LoadingIndicator size="4x" />
          <br />
          Loading...
        </div>}
        {org && org !== "NONE" && (
          <div>
            {org.logoFile && <div style={{padding: "20px", textAlign: "center"}}>
              <img alt={`Logo for ${org.name}`} src={getFileDownloadUrl(org.logoFile)} />
            </div>}
            <div style={{ padding: "20px" }}>
              <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(org.description || "")) }}></div>
            </div>
            <div style={{ padding: "20px", textAlign: "center" }}>
              <Link to="/apply"  >
                <button>Start your application now!</button>
              </Link>
            </div>
          </div>
        )}
        {org === "NONE" && (
          <>
            <div>
              <p>
                Welcome to Dreamstack Applications! We make it easy to gather information from leads, users and clients.
            <br /><br />
            Our service starts free for all users and provides affordable upgrade options for your needs
            as your business scales.
          </p>
            </div>
            <div className="moreInfo">
              <h3>How to get started</h3>
              <StepsList>
                <li>Create your free Dreamstack account</li>
                <li>Create your first program using a free template</li>
                <li>Customize your application process</li>
                <li>Share and immediately start collecting and reviewing applications</li>
              </StepsList>
            </div>
            {isUserCreator(user) && (<div>
              You are logged in as a creator. Create or manage your org now!
            </div>)}
            <div>
              <h3>About Us</h3>
              <p>
                Dreamstack got started because there were no good, affordably options for collecting and processing
                applications. As a small business, I needed an option that performed better than a form and spreadsheet,
                but that wouldn't cost thousands of dollars per year.
          </p>
              <p>
                Dreamstack is still in active development. We are committed to supporting all of our users and are
                responsive to feature requests and bug reports.
          </p>
            </div>
          </>
        )}
      </FixedContainer>
    </PageBody>
  );
};