import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { makeRequest } from "../../common/api";
import { justConfirm } from "../../common/components/Modals";
import {
  ListItem,
  ListItemBody,
  LoadingPage,
  PageTitle,
  useTitle,
} from "../../common/features/Utils";

interface Props {
  title: string; // Ex: "Webhooks"
  resourceKey: string; // Ex: "webhooks"

  listItemContent: typeof React.Component;
  editItemForm: typeof React.Component;
}
export const SimpleResourceList = (props: Props) => {
  useTitle(props.title);
  const [items, setItems] = React.useState<any[] | null>(null);
  const [activeItem, setActiveItem] = React.useState<any | null>(null);
  const [isAdding, setIsAdding] = React.useState<boolean>(false);

  const fetchItems = () => {
    setItems(null);
    makeRequest(`/api/${props.resourceKey}`, "GET").then((response) =>
      setItems(response)
    );
  };
  React.useEffect(() => {
    fetchItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const tryDeleteItem = (item: any) => {
    justConfirm(
      `Are you sure you want to delete this ${props.resourceKey}?`
    ).then(() => {
      makeRequest(`/api/${props.resourceKey}/${item._id}`, "DELETE").then(
        () => {
          setItems([...(items || [])].filter((x) => x._id !== item._id));
        },
        (err) => alert("Failed to delete: " + err.message)
      );
    });
  };

  if (!items) {
    return <LoadingPage />;
  }

  const ListItemContent = props.listItemContent;
  const EditItemForm = props.editItemForm;

  return (
    <div>
      <PageTitle>{props.title}</PageTitle>
      {!isAdding &&
        !activeItem &&
        items.map((item, index) => (
          <ListItem key={item._id}>
            <b>{item.name || "Item #" + (index + 1)}</b>
            <button
              className="secondary inline"
              onClick={() => setActiveItem(item)}
            >
              Edit
            </button>
            <button
              className="secondary inline"
              onClick={() => tryDeleteItem(item)}
            >
              Delete
            </button>
            <ListItemBody>
              <ListItemContent item={item} />
            </ListItemBody>
          </ListItem>
        ))}
      <div>
        {!isAdding && !activeItem && (
          <button className="" onClick={() => setIsAdding(true)}>
            <FontAwesomeIcon icon="plus" /> New {props.resourceKey}
          </button>
        )}
      </div>
      <div>
        {(isAdding || activeItem) && (
          <EditItemForm
            item={activeItem}
            onSave={async (item: any) => {
              await (isAdding
                ? makeRequest(`/api/${props.resourceKey}`, "POST", item)
                : makeRequest(
                    `/api/${props.resourceKey}/${item._id}`,
                    "PUT",
                    item
                  ));
              if (isAdding) {
                setIsAdding(true);
                setItems(null);
              }

              setIsAdding(false);
              setActiveItem(null);
              fetchItems();
            }}
            onClose={() => {
              setIsAdding(false);
              setActiveItem(null);
            }}
          />
        )}
      </div>
    </div>
  );
};
