import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext } from "react";
import { Application, makeRequest } from "../../common/api";
import { ApplicationCard } from "../../common/features/ApplicationCard";
import { UserContext } from "../../common/UserContext";
import { BatchesContext } from "../BatchesContext";

export const YourApplications = () => {
  const [applications, setApplications] = React.useState<Application[] | null>(null);
  const batches = React.useContext(BatchesContext)?.batches;

  const userContext = useContext(UserContext);
  React.useEffect(() => {
    if (userContext?.user)
      makeRequest("/api/applications", "GET").then((apps: Application[]) => {
        setApplications(apps);
      })
  }, [userContext]);


  const activeApplications = applications?.filter(a => a.status !== "CANCELLED");
  return (
    <div>
      {
        batches && activeApplications && activeApplications.length > 0 && (
          <>
            <h3>Your applications</h3>
            {
              !batches && <FontAwesomeIcon icon="circle-notch" spin={true} />
            }
            {
              batches && activeApplications?.map(app => <ApplicationCard key={app._id} application={app} batches={batches} />)
            }
          </>
        )
      }
    </div>
  );
};