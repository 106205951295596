import React from "react";
import styled from "styled-components";
import { ErrorLogEntry, makeRequest } from "../../common/api";
import { justConfirm } from "../../common/components/Modals";
import {
  ListItem,
  LoadingPage,
  PageTitle,
  useTitle,
} from "../../common/features/Utils";

const Pre = styled.pre`
  display: inline;
  padding: 4px;
`;

/** Admin tool for managing email templates */
export const ErrorLogs = () => {
  useTitle("Error Logs");
  const [entries, setEntries] = React.useState<ErrorLogEntry[] | null>(null);

  const fetchEntries = () => {
    setEntries(null);
    makeRequest("/api/errorLogs", "GET").then((response) =>
      setEntries(response)
    );
  };
  React.useEffect(() => {
    fetchEntries();
  }, []);

  const tryDeleteEntry = (entry: ErrorLogEntry) => {
    justConfirm(`Are you sure you want to delete this log entry?`).then(() => {
      makeRequest(`/api/errorLogs/${entry._id}`, "DELETE").then(
        () => {
          setEntries(
            [...(entries || [])].filter((x) => x._id !== entry._id)
          );
        },
        (err) => alert("Failed to delete: " + err.message)
      );
    });
  };

  if (!entries) {
    return <LoadingPage />;
  }

  return (
    <div>
      <PageTitle>Error Logs</PageTitle>
      <p>
        A list of relevant error events that have occurred in your organization. Errors in this
        section likely indicate that something is misconfigured, causing an email, webhook or API
        call to fail.
      </p>
      {entries.length === 0 && <pre>Great news! You have no error log entries. Your integrations seem to be working correctly.</pre>}
      {
        entries.map((entry) => (
          <div key={entry._id}>
            <pre>
              <button
                style={{float: "right"}}
                className="secondary inline"
                onClick={() => tryDeleteEntry(entry)}
              >
                Delete
              </button>
              [{new Date(entry.createdAt).toLocaleString()}]{"   "}
              {entry.message}
            </pre>
          </div>
        ))}
    </div>
  );
};
