import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { useHistory } from "react-router-dom";
import { makeRequest, Process } from "../../common/api";
import { justConfirm } from "../../common/components/Modals";
import { ListItem, ListItemBody, LoadingIndicator, PageTitle, useTitle, ZeroState } from "../../common/features/Utils";
import { NEW_PROCESS, ProcessConfigEditor } from "./ProcessConfigEditor";

export const Processes = () => {
  useTitle("Forms and Processes");
  const history = useHistory();
  const [processes, setProcesses] = React.useState<Process[] | null>();
  React.useEffect(() => {
    makeRequest("/api/processConfigBlobs", "GET").then(blobs => {
      setProcesses(blobs.map(b => {
        const parsed = JSON.parse(b.json);
        parsed._id = b._id;
        return parsed;
      }));
    }, err => alert(err.message));
  }, []);
  const tryDeleteProcess = (p: Process) => {
    justConfirm(`Are you sure you want to delete process '${p.label}'?`).then(() => {
      makeRequest(`/api/processConfigBlobs/${p._id}`, "DELETE").then(() => {
        setProcesses([...(processes || [])].filter(x => x._id !== p._id));
      }, err => alert("Failed to delete: " + err.message))
    })
  };

  const [isAdding, setIsAdding] = React.useState(false);
  const [selectedProcess, setSelectedProcess] = React.useState<Process | null>();

  if (!processes) {
    return <LoadingIndicator size="4x" />
  }

  return <div>
    <PageTitle>Your Forms and Processes</PageTitle>
    {(isAdding || selectedProcess) &&
      <ProcessConfigEditor
        isAdding={isAdding}
        process={selectedProcess}
        onSaved={(process) => {
          if (isAdding) {
            setProcesses([...processes, process]);
            setIsAdding(false);
            setSelectedProcess(process);
          } else {
            const oldIndex = processes.indexOf(selectedProcess);
            const newProcesses = [...processes];
            newProcesses[oldIndex] = process;
            setProcesses(newProcesses);
            setSelectedProcess(process);
          }
        }}
        onClose={() => {
          setSelectedProcess(null);
          setIsAdding(false);
        }}
      />}

    {!isAdding && !selectedProcess && (
      <>
        {processes?.length === 0 && <ZeroState>Your organization hasn't defined any application forms yet. Create one now?</ZeroState>}
        {processes.map(p => (<ListItem key={p._id}>
          <b>{p.label}</b>{" "}
          <button className="secondary inline" onClick={() => history.push(`/admin/processes/${p._id}`)}>Edit</button>
          <button className="secondary inline" onClick={() => tryDeleteProcess(p)}>Delete</button>
          <ListItemBody>
            {p.applicationForm?.fields?.map(f => f.label)?.join(", ")}
            {" "}plus{" "}
            {p.applicationDetailsSteps.length - 1} other steps
          </ListItemBody>
        </ListItem>))}
      </>
    )}
    <div>
      {!isAdding && !selectedProcess && (<button className="" onClick={() => {
        setIsAdding(true);
        setSelectedProcess({ ...NEW_PROCESS });
      }}>
        <FontAwesomeIcon icon="plus" /> New form
      </button>)}
    </div>
  </div>
};