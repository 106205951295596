import React from "react"
import { makeRequest } from "../common/api";
import { PageBody } from "../common/components/PageBody";
import { LoadingIndicator } from "../common/features/Utils";
import { UserContext } from "../common/UserContext";

export const CreateOrganizationPage = () => {
    const user = React.useContext(UserContext)?.user;
    const [errorMessage, setErrorMessage] = React.useState("");
    const [isLoading, setIsLoading] = React.useState(false);
    const [name, setName] = React.useState("");

    const doCreate = async () => {
        setIsLoading(true);

        try {
            const response = await makeRequest("/api/organizations", "POST", { name });

            if (response && response._id) {
                window.location.href = "/admin/config?organizationId=" + response._id;
            } else {
                setIsLoading(false);
                setErrorMessage("Something went wrong")
            }
        } catch (ex) {
            setIsLoading(false);
            setErrorMessage(ex.message);
        }
    };

    if (!user) {
        return <PageBody>You must be signed in to create an organization</PageBody>;
    }

    return <PageBody>
        <h2>Create a new organization</h2>
        <p>
            Dreamstack uses "organizations" as the primary way of managing groups of application
            processes, user roles and more. Existing users can create new organizations, and invite
            users to join those organizations.
        </p>

        <div>
            <label>Organization name</label>
            <input type="text" value={name} onChange={e => setName(e.target.value)}
                disabled={isLoading} />
        </div>
        {errorMessage && (<div style={{ color: "red" }}>{errorMessage}</div>)}
        <div>
            <button onClick={doCreate} disabled={isLoading}>
                {isLoading && <LoadingIndicator />}{" "}
                Create
            </button>
        </div>


    </PageBody>;
}