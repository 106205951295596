import { Application, DynamicCondition, DynamicConditionTarget, DynamicConditionTerm, Solution, STATUS_VALUES } from "../api";

const findConditionTarget = (term: DynamicConditionTerm, application: Application, solutions: Solution[] | null) => {
  const { target, targetDetails } = term;

  if (target === "ASSESSMENTS_COMPLETE") {
    return solutions?.length || 0;
  } else if (target === "STATUS") {
    const statusCode = normalizeConditionValue(target, application.status)

    return statusCode;
  } else if (target === "FIELD") {
    return (application as any)[targetDetails!];
  } else {
    throw new Error("Invalid target provided. This should not be possible. Contact the site administrator for support.");
  }
};
/** 
* Normalizes the literal value provided as the comparisonValue to a format where comparisons function.
* Ex: Converts STATUS into a status code for comparisons
*/
const normalizeConditionValue = (target: DynamicConditionTarget, value: number | string | undefined) => {
  if (target === "STATUS") {
    return STATUS_VALUES.indexOf(value as string);
  }

  return value;
}
export const checkConditionTerm = (condition: DynamicConditionTerm, application: Application, solutions: Solution[] | null) => {
  try {
    const targetValue: any = findConditionTarget(condition, application, solutions);
    const comparisonValue: any = normalizeConditionValue(condition.target, condition.comparisonValue);

    if (condition.operator === "Exists") {
      return !!targetValue;
    }

    if (condition.operator === "Greater than") {
      return targetValue > comparisonValue;
    }

    if (condition.operator === "Group by") {
      return true;
    }

  } catch (ex) {
    console.log("Failed to execute condition due to " + ex.message);
    return false;
  }
};
export const checkCondition = (condition: DynamicCondition, application: Application, solutions: Solution[] | null) => {
  if (condition.terms.length === 0) {
    return true;
  }

  for (let term of condition.terms) {
    const termPassed = checkConditionTerm(term, application, solutions);
    if (condition.logicalOperator === "OR" && termPassed) {
      return true;
    }
    if (condition.logicalOperator === "AND" && !termPassed) {
      return false;
    }
  }

  // Use the unnecessary ternary here to make the logic clear: true and false have different polarities in this function
  // and the case where the loop was exhausted is different for each. 
  return condition.logicalOperator === "AND" ? true : false;
};