import React from "react";
import styled from "styled-components";
import { Application, makeRequest, Process } from "../api";
import { BatchesContext } from "../BatchesContext";
import { UserContext } from "../UserContext";
import { getCapturedQueryParams, trackAnalyticsEvents } from "./Analytics";
import { DynamicForm } from "./DynamicForm";
import { LoadingIndicator, Required } from "./Utils";

const FormRow = styled.div`
    margin-bottom: 10px;
`;

interface Props {
    /** Callback executed after an application is submitted */
    onSubmitted: (application: Application, process: Process) => void;
    /** Set to true to prevent automatic userId/email binding, for admin use cases. */
    isAnonymous?: boolean;
}
export const ApplyForm = (props: Props) => {
    const batches = React.useContext(BatchesContext)?.batches;
    const userContext = React.useContext(UserContext);

    const [email, setEmail] = React.useState(props.isAnonymous ? "" : userContext?.user?.email || "");
    const [batchId, setBatchId] = React.useState("");

    React.useEffect(() => {
        if (batches && batches.length > 0) {
            const activeBatches = batches.filter(b => b.status === "Open")
            setBatchId(activeBatches[0]?._id || "");
        }
    }, [batches]);
    React.useEffect(() => {
        if (!props.isAnonymous && userContext?.user?.email) {
            setEmail(userContext?.user?.email);
        }
    }, [userContext, props.isAnonymous]);

    const [process, setProcess] = React.useState<Process | null>();
    React.useEffect(() => {
        const batch = batches?.find(b => b._id === batchId);
        if (batch) {
            makeRequest(`/api/processConfigBlobs/${batch.processId}`, "GET").then(blob => {
                setProcess(JSON.parse(blob.json) as any);
            });
        }
    }, [batchId, batches]);

    return (
        <div>
            <h3 style={{ marginBottom: "20px" }}>
                Choose a program
            </h3>
            <FormRow>
                <label htmlFor="batch">Batch<Required /></label>
                <select required id="batch" value={batchId} onChange={e => setBatchId(e.target.value)}>
                    {batches?.filter(b => b.status === "Open").map(batch =>
                        <option key={batch._id} value={batch._id}>
                            {batch.name}
                        </option>)}
                </select>
            </FormRow>

            {process ? <DynamicForm configuration={process.applicationForm}
                preprocess={(app: Application) => {
                    const params = getCapturedQueryParams();
                    if (params) {
                        app.tracking = app.tracking || {} as any;
                        app.tracking.source = params.get("utm_source") || undefined;
                        app.tracking.medium = params.get("utm_medium") || undefined;
                        app.tracking.campaign = params.get("utm_campaign") || undefined;
                        app.tracking.term = params.get("utm_term") || undefined;
                        app.tracking.content = params.get("utm_content") || undefined;
                    }
                    return app;
                }}
                onSubmitted={(application: Application) => {
                    trackAnalyticsEvents('SubmitApplication');

                    props.onSubmitted(application, process);
                }}
                initialEntry={{ batchId, email }} /> : <LoadingIndicator />}
        </div>
    );
}