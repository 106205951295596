import { FileInfo } from "../api";
import styled from "styled-components";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageBody } from "../components/PageBody";
import marked from 'marked';
import DOMPurify from 'dompurify';

export const truncateInMiddle = (str: string) => {
    if (!str) {
        return "";
    }

    if (str.length > 22) {
        return str.substring(0, 12) + "..." + str.substring(str.length - 4, str.length);
    }
    return str;
}

export const formatDurationRough = (millis: number) => {
    const seconds = millis / 1000;
    const minutes = seconds / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    if (days > 1) {
        return Math.floor(days) + " days";
    } else if (hours > 1) {
        return Math.floor(hours) + " hours";
    } else {
        return Math.ceil(minutes) + " minutes";
    }
};

export const formatMinutes = (minutes: number) => {
    const days = Math.floor(minutes / (60 * 24));
    const hours = Math.floor(minutes / 60) % 24;
    const mins = minutes % 60;

    if (days > 0) {
        let str = `${days} day`;
        if (days > 1) str += "s";
        if (hours > 0) str += `, ${hours} hour`;
        if (hours > 1) str += "s";
        if (mins > 0) str += `, ${mins} minute`;
        if (mins > 1) str += "s";
        return str;
    }
    if (hours > 0) {
        let str = `${hours} hour`;
        if (hours > 1) str += "s";
        if (mins > 0) str += `, ${mins} minute`;
        if (mins > 1) str += "s";
        return str;
    } else {
        return `${mins} minutes`;
    }
}

export const getFileDownloadUrl = (file: FileInfo) => {
    if (file && file.cdnUrl) {
        return file.cdnUrl;
    }

    if (file && file.fileId && file.fileName) {
        return "/api/download/" + file.fileId + "/" + encodeURIComponent(file.fileName);
    } else {
        return "";
    }
};

interface AttachmentLinkProps {
    file: FileInfo;
}
export const AttachmentLink = (props: AttachmentLinkProps) => {
    if (!props.file) {
        return <></>;
    }
    // download={props.file.fileName}
    return (<a href={getFileDownloadUrl(props.file)}
        target="_blank"
        rel="noopener noreferrer"
        title={props.file.fileName}>
        {truncateInMiddle(props.file.fileName)}
    </a>);
};

const RedSpan = styled.span`
    color: red;
    margin-left: 2px;
`;
export const Required = () => (<RedSpan>*</RedSpan>);


export interface StatusIconProps {
    status: "new" | "active" | "complete";
    size?: string;
}
export const StatusIcon = (props: StatusIconProps) => {
    const size: any = props.size || "4x";
    if (props.status === "complete") {
        return (<FontAwesomeIcon style={{ color: "#3A3" }} icon="check-circle" size={size} />);
    }

    if (props.status === "active") {
        return (<FontAwesomeIcon style={{ color: "#F0681A" }} icon="clock" size={size} />);
    }

    return <FontAwesomeIcon style={{ color: "#BBB" }} icon="circle" size={size} />;
};

interface LoadingPageProps {
    delayedMessage?: string;
}
export const LoadingPage = (props: LoadingPageProps) => {
    const [message, setMessage] = React.useState("");

    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setMessage(props.delayedMessage);
        }, 1000);

        return () => {
            clearTimeout(timeout);
        };
    }, [props.delayedMessage]);

    return (<PageBody>
        Loading...
        <div>
            <FontAwesomeIcon icon="circle-notch" spin={true} size="4x" />
        </div>
        {message && <div>{message}</div>}
    </PageBody>);
};

export interface LoadingIndicatorProps {
    size?: "1x" | "2x" | "4x"
}
export const LoadingIndicator = (props: LoadingIndicatorProps) => {
    return <FontAwesomeIcon icon="circle-notch" spin={true} size={props.size || "1x"} />;
}

const ZeroStateText = styled.div`
    display: inline-block;
    margin: auto;

    font-size: 16px;
    color: #777;
    padding: 20px 40px;
`;
export const ZeroState = (props: any) => {
    return <div style={{ textAlign: "center" }}>
        <ZeroStateText>{props.children}</ZeroStateText>
    </div>;
}

/** A Pre-formatted block that is not Code */
export const Pre = styled.pre`
    font-family: "Merriweather Sans", sans-serif;
    word-break: normal;
    background-color: transparent;
    border: none;
`;

interface InfoTipProps {
    tip: string;
}
export const InfoTip = (props: InfoTipProps) => {
    return <FontAwesomeIcon size="1x" title={props.tip} icon="question-circle" />
}

export const MarkdownTip = () => {
    return <FontAwesomeIcon size="1x" icon="code" title="This field supports markdown input." />
}

export const PageTitle = styled.h3`
    margin-bottom: 30px;
`;

export interface MarkdownProps {
    content: string;
    style?: React.CSSProperties;
    className?: string;
}
export const Markdown = (props: MarkdownProps) => {
    if (!props.content) {
        return <div style={props.style} className={props.className}></div>
    }

    return <div style={props.style} className={props.className}
        dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(marked(props.content || "")) }}></div>
}

export const getMonthDate = (dateString: string) => {
    const date = new Date(dateString);
    return (date.getMonth() + 1) + "/" + date.getDate();
}

export const getYearMonthDate = (dateString: string) => {
    const date = new Date(dateString);
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
}

export const getTimestampString = (dateString: string) => {
    const date = new Date(dateString);
    return date.getHours() + ":" + date.getMinutes().toString().padStart(2, "0") + " on " + date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

export const arrayReplace = (array: any[], value: any, index: number) => {
    array = array || [];
    const copy = [...array];
    copy[index] = value;
    return copy;
};

export const arrayRemoveItem = (array: any[], item: any) => {
    array = array || [];
    const copy = [...array];
    const index = array.indexOf(item);
    if (index >= 0) {
        copy.splice(index, 1);
    }
    return copy;
};

export const arrayRemove = (array: any[], index: number) => {
    array = array || [];
    const copy = [...array];
    copy.splice(index, 1);
    return copy;
};


const IconWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-right: 10px;
`;

const IconImage = styled.img`
    width: 70px;
    max-height: 70px;
    margin: auto;
`;

interface IconProps {
    file: FileInfo;
}
export const ListItemIcon = (props: IconProps) => {
    if (!props.file) {
        return null;
    }

    return (
        <IconWrapper>
            <IconImage src={getFileDownloadUrl(props.file)} alt="" />
        </IconWrapper>)
}


export const ListItem = styled.div`
  padding: 10px;
  margin: 10px 20px 20px 20px;
  border-radius: 5px;
  background-color: #FFF;
  border: 1px solid #AAA;
`;
export const ListItemBody = styled.div`
    margin-top: 10px;
    color: #666;
`;

export const FlexRow = styled.div`
    display: flex;
    align-items: center;
`;

interface ImageUploadProps {

}
export const ImageUploadRow = (props: ImageUploadProps) => {

};

export function useTitle(title) {
    React.useEffect(() => {
      const prevTitle = document.title
      document.title = title
      return () => {
        document.title = prevTitle
      }
    }, [title]);
  }