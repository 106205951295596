import {
  faCheckCircle, faCircle, faCircleNotch, faCheckSquare, faCode,
  faFileAlt, faPlus, faQuestionCircle, faStar, faTimes, faMinus,
  faUserTag, faEdit, faSave, faCheck, faTrash, faCodeBranch, faClock,
  faSortNumericUp, faChevronDown, faSort, faSortDown, faSortUp,
  faFilter, faArrowLeft,
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(
  faCheckCircle, faCircle, faCircleNotch, faCheckSquare, faCode,
  faFileAlt, faPlus, faQuestionCircle, faStar, faTimes, faMinus,
  faUserTag, faEdit, faSave, faCheck, faTrash, faCodeBranch, faClock,
  faSortNumericUp, faChevronDown, faSort, faSortDown, faSortUp,
  faFilter, faArrowLeft
);

// Export something so it's a module or something, and add a function so I can do something lol
export const Library = { library, noop: () => { } };