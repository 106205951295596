import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DOMPurify from "dompurify";
import React from "react";
import styled from "styled-components";
import { EmailTemplate, makeRequest, makeUploadRequest, Organization } from "../../common/api";
import { justConfirm } from "../../common/components/Modals";
import { AttachmentLink, LoadingIndicator } from "../../common/features/Utils";
import { OrgContext } from "../../common/OrgContext";

const Pre = styled.pre`
    display: inline;
    padding: 4px;
`;

interface Props {
    template: EmailTemplate | null;
    onSave: (template: EmailTemplate) => Promise<void>;
    onClose: () => void;
}
export const EditEmailTemplateForm = (props: Props) => {
    const org = React.useContext(OrgContext);
    const [template, setTemplate] = React.useState<EmailTemplate>(props.template || {
        name: "",
        subject: "",
        body: "",
        attachments: []
    } as any);

    const [isLoading, setIsLoading] = React.useState(false);

    const [attachmentUpload, setAttachmentUpload] = React.useState<any>(null);
    const onFileInputChange = (e: any) => {
        if (!props.template) {
            return;
        }

        setAttachmentUpload(e.target.files[0]);

        makeUploadRequest("emailTemplate", props.template._id, "attachment", e.target.files[0], org && org !== "NONE" && org._id).then(fileInfo => {
            const newTemplate = { ...template };

            newTemplate.attachments = newTemplate.attachments || [];
            newTemplate.attachments.push(fileInfo);
            setTemplate(newTemplate);
            setAttachmentUpload(null);
        }, err => {
            alert("Failed with error " + err)
        })
    };

    return (<div>
        <div>
            <label>Template Name</label>
            <input type="text" disabled={template.hasSpecialName} value={template.name} onChange={e => setTemplate({ ...template, name: e.target.value })} />
        </div>
        <div>
            <label>Subject</label>
            <input type="text" value={template.subject} onChange={e => setTemplate({ ...template, subject: e.target.value })} />
        </div>
        <div>
            <label>Body</label>
            <textarea rows={12} cols={160} value={template.body} onChange={e => setTemplate({ ...template, body: e.target.value })} />
        </div>
        <div>
            {!props.template && "To upload attachments, first save the draft template. You can edit it later."}
            {props.template && <div>
                <label htmlFor="attachment"></label>
                <input id="attachment" type="file" onChange={onFileInputChange} disabled={isLoading} />
            </div>}
        </div>
        <div style={{ marginTop: "20px" }}>
            <button disabled={isLoading} type="button" onClick={async () => {
                setIsLoading(true);
                await props.onSave(template);
                setIsLoading(false);
            }}>
                {isLoading && <LoadingIndicator />}
                Save changes
            </button>
            <button disabled={isLoading} className="secondary" type="button" onClick={props.onClose}>
                <FontAwesomeIcon icon="times" /> Cancel
            </button>
            <div>
                <b>Email uses HTML formatting, but line breaks are auto-added.</b>
                <br /><br />
                User template strings: <Pre>{"{firstName}"}</Pre>, <Pre>{"{lastName}"}</Pre>,
                 <Pre>{"{email}"}</Pre>, <Pre>{"{orgName}"}</Pre>,
                  and <Pre>{"{applicationLink}"}</Pre>.
            </div>
        </div>
        <h4>Email Preview</h4>
        <div style={{ marginTop: "20px", padding: "10px", border: "1px solid #CCC" }}>
            <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(fillTemplatesWithSamples(brify(template.body), org)) }}></div>

            {template?.attachments?.map(a => (
                <div key={a.fileId} style={{ padding: "10px", border: "1px solid #CCC", margin: "10px", display: "inline-block" }}>
                    <AttachmentLink file={a} />
                    <button className="secondary inline"
                        onClick={async () => {
                            await justConfirm("Are you sure you want to remove attachment '" + a.fileName + "'?");

                            const newTemplate = { ...template, attachments: template.attachments?.filter(x => x.fileId !== a.fileId) };
                            setTemplate(newTemplate);
                            props.onSave(newTemplate);

                            makeRequest(`/api/files/${a.fileId}`, "DELETE");
                        }}>
                        <FontAwesomeIcon icon="times" />
                    </button>
                </div>
            ))}
            {attachmentUpload && <div>Uploading file...</div>}
        </div>
    </div>);
}

export const brify = (body: string) => {
    return body.replace(/\n/gi, "<br/>");
}

export const fillTemplatesWithSamples = (text: string, org?: Organization | "NONE") => {
    return text.replace("{firstName}", "John")
        .replace("{lastName}", "Smith")
        .replace("{email}", "johnsmith@applift.com")
        .replace("{applicationId}", "abcdef1234567890")
        .replace("{applicationLink}", `<a href="#">https://${(org && org !== "NONE" && org.fullDomain) || "www.dreamstack.app"}/application/abcdef1234567890</a>`)
        .replace("{orgName}", (org && org !== "NONE" && org.name) || "us");
}