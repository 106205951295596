import React from "react";
import { useParams } from "react-router-dom";
import { Batch, makeRequest } from "../common/api";
import { LoadingPage, Markdown } from "../common/features/Utils";
import { UserContext } from "../common/UserContext";
import { PageBody } from "../common/components/PageBody";

/**
 * A page which contains basic and advanced details about a
 * batch. Basic information like schedule is always available.
 * Sensitive information like payment plans are provided for students
 * who are signed in with an active application for the batch.
 */
export const BatchDetailsPage = () => {
    const user = React.useContext(UserContext)?.user;

    const { batchId } = useParams<any>();
    const [batch, setBatch] = React.useState<Batch | null>(null);
    React.useEffect(() => {
        makeRequest(`/api/batches/${batchId}`, "GET").then(newBatch =>
            setBatch(newBatch));
    }, [batchId])

    if (!batch) {
        return <LoadingPage />
    }

    return (<PageBody>
        <h2>{batch.name}</h2>
        <Markdown content={batch.description} />

        <h3>Additional Details</h3>
        {user ? (
            <div>

                <Markdown content={batch.privateDetails} />
            </div>
        ) : (
                <div>
                    To see additional details, please sign in.
                </div>
            )}

    </PageBody>);
}