import styled from "styled-components";

export const PageBody = styled.div<{fullHeight?: boolean}>`
  padding: 20px;
  overflow: auto;
  width: 100%;
  height: ${p => p.fullHeight ? "100%" : "calc(100% - 71px)"};

  @media screen and (max-width: 400px) {
    padding: 10px 5px;
  }
`;

export const FixedContainer = styled.div`
  max-width: 1024px;
  margin: 10px auto;
`;