import React from "react";
import styled from "styled-components";
import { LoginForm } from "../common/features/LoginForm";
import { UserContext } from "../common/UserContext";

const Body = styled.div`
  text-align: center;
`;

interface Props {
  startOnSignup?: boolean;
}

export const LoginPage = (props: Props) => {
  const userContext = React.useContext(UserContext);
  React.useEffect(() => {
    if (userContext?.user) {
      window.location.href = "/";
    }
  }, [userContext]);

  return <Body>
    <LoginForm startOnSignup={props.startOnSignup} isSignupOnly={false} onComplete={() => {
      window.location.href = "/";
    }} />
  </Body>;
};