import { IS_ADMIN_REF, makeRequest } from "../api";

export type TrackedAction = "VIEW_APPLICATION" | "VIEW_APPLICATION_STEP" | "SAVE_APPLICATION_STEP" |
  "SUBMITTED_ASSESSMENT" | "STARTED_ASSESSMENT" | "SCHEDULED_INTERVIEW";

// TODO: Add a queueing system which batches these requests
export const trackAction = (applicationId: string, action: TrackedAction, actionDetails?: string) => {
  if (!IS_ADMIN_REF.isAdmin) {
    makeRequest("/api/userActivity", "POST", {
      applicationId: applicationId,
      activityEntries: [
        {
          action: action,
          actionDetails: actionDetails,
          timestamp: new Date()
        }
      ]
    });
  }
};