import React from "react";
import { Assessment, Batch } from "../../common/api";
import { EditBatchForm } from "../../common/features/EditBatchForm";
import { FlexRow, ListItem, ListItemBody, ListItemIcon, PageTitle, useTitle, ZeroState } from "../../common/features/Utils";


interface Props {
    assessments: Assessment[];
    batches: Batch[];
    onChange: () => void;
}
export const Batches = (props: Props) => {
    useTitle("Batches");
    const [isAdding, setIsAdding] = React.useState(false);
    const [selectedBatch, setSelectedBatch] = React.useState<Batch | null>(null);
    const [isCompletedVisible, setIsCompletedVisible] = React.useState(false);

    const visibleBatches = (isCompletedVisible ? props.batches : props.batches?.filter(b => b.status !== "Complete")) || [];

    visibleBatches.sort((a: Batch, b: Batch) => {
        return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });

    return (<div>
        <PageTitle>Your Batches</PageTitle>
        <div>
            {props.batches === null && "Loading..."}
            {props.batches?.length === 0 && !isAdding && !selectedBatch &&
                <ZeroState>Your organization hasn't created any batches yet. Time to start one?</ZeroState>}
                <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div>
                        Showing {visibleBatches.length} of {props.batches?.length || 0} batches.
                    </div>
                    <div>
                    <label style={{ width: "auto" }}>
                        <input type="checkbox" checked={isCompletedVisible} style={{width: "auto", marginRight: "10px"}}
                            onChange={e => setIsCompletedVisible(e.target.checked)} />
                        Show completed batches
                    </label>
                    </div>
                </div>
            {!isAdding && !selectedBatch &&
                <>{visibleBatches?.map(batch => (<ListItem key={batch._id}>
                    <FlexRow>
                        <ListItemIcon file={batch.iconFile || batch.bannerFile} />
                        <div>
                            <span><b>{batch.name}</b> ({batch.status})</span>
                            <button className="inline secondary"
                                onClick={() => setSelectedBatch(batch)}>
                                Edit
                            </button>{"  "}
                            <a href={`/batch/${batch._id}`}>
                                <button className="inline secondary">
                                    View
                            </button>
                            </a>
                            <ListItemBody>
                                {batch.description?.substring(0, 200)}
                                {batch.description?.length > 200 && "..."}
                            </ListItemBody>
                        </div>
                    </FlexRow>
                </ListItem>))}
                    <br />
                    <button onClick={() => setIsAdding(true)}>New Batch</button>
                </>}
        </div>
        {isAdding &&
            <EditBatchForm
                batch={null}
                isAdding={true}
                onChange={() => {
                    setIsAdding(false);
                    props.onChange();
                }}
                onClose={() => setIsAdding(false)} />
        }


        {
            selectedBatch && (
                <div>
                    <h3>Edit Batch</h3>
                    <EditBatchForm
                        batch={selectedBatch}
                        onChange={() => {
                            setSelectedBatch(null);
                            props.onChange();
                        }}
                        onClose={() => {
                            props.onChange();
                            setSelectedBatch(null);
                        }}
                    />
                </div>
            )
        }
    </div>);
};