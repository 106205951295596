import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { EmailTemplate, makeRequest } from "../../common/api";
import { justConfirm } from "../../common/components/Modals";
import { ListItem, ListItemBody, LoadingPage, PageTitle, useTitle } from "../../common/features/Utils";
import { EditEmailTemplateForm } from "./EditEmailTemplateForm";

/** Admin tool for managing email templates */
export const Emails = () => {
    useTitle("Email Templates");
    const [templates, setTemplates] = React.useState<EmailTemplate[] | null>(null);
    const [activeTemplate, setActiveTemplate] = React.useState<EmailTemplate | null>(null);
    const [isAdding, setIsAdding] = React.useState<boolean>(false);

    const fetchTemplates = () => {
        setTemplates(null);
        makeRequest("/api/emailTemplates", "GET").then(response => setTemplates(response));
    }
    React.useEffect(() => {
        fetchTemplates();
    }, []);

    const tryDeleteTemplate = (t: EmailTemplate) => {
        justConfirm(`Are you sure you want to delete template '${t.name}'?`).then(() => {
            makeRequest(`/api/emailTemplates/${t._id}`, "DELETE").then(() => {
                setTemplates([...(templates || [])].filter(x => x._id !== t._id));
            }, err => alert("Failed to delete: " + err.message))
        })
    };

    if (!templates) {
        return <LoadingPage />;
    }

    return (<div>
        <PageTitle>Your Email Templates</PageTitle>
        {!isAdding && !activeTemplate && templates.map(t => (<ListItem key={t.name}>
            <b>{t.name}</b>
            <button className="secondary inline" onClick={() => setActiveTemplate(t)}>Edit</button>
            <button className="secondary inline" onClick={() => tryDeleteTemplate(t)}>Delete</button>
            <ListItemBody>
                {t.body.substring(0, 200)}
                {t.body.length > 200 && "..."}
            </ListItemBody>
        </ListItem>))
        }
        <div>
            {!isAdding && !activeTemplate && (<button className="" onClick={() => setIsAdding(true)}>
                <FontAwesomeIcon icon="plus" /> New template
            </button>)}
        </div>
        <div>
            {(isAdding || activeTemplate) && (
                <EditEmailTemplateForm
                    template={activeTemplate}
                    onSave={async (template: EmailTemplate) => {
                        await isAdding ?
                            makeRequest("/api/emailTemplates", "POST", template) :
                            makeRequest(`/api/emailTemplates/${template._id}`, "PUT", template);
                        if (isAdding) {
                            setIsAdding(true);
                            setTemplates(null);
                        }
                        fetchTemplates();

                        setIsAdding(false);
                        setActiveTemplate(null);
                        fetchTemplates();
                    }}
                    onClose={() => {
                        setIsAdding(false);
                        setActiveTemplate(null);
                    }} />
            )}
        </div>
    </div >);
};