import React from "react";
import { PaymentModel, makeRequest } from "../api";
import { TextInput } from "../components/Forms";
import { LoadingIndicator, MarkdownTip } from "./Utils";

export const getPaymentModelDescription = (model: PaymentModel) => {
    let text = `${model.currency} ${model.upfrontCost} upfront`;
    if (model.monthlyCost && model.numberOfMonths) {
        text += `, plus ${model.monthlyCost}/mo for ${model.numberOfMonths} months`;
        
        if (model.monthlyPaymentDelay) {
            text += ` starting after ${model.monthlyPaymentDelay} months`;
        }
    }

    return text;
}

const NEW_MODEL: PaymentModel = {
    _id: "",
    organizationId: "",
    name: "",
    details: "",
    upfrontCost: 0,
    monthlyCost: 0,
    numberOfMonths: 0,
    monthlyPaymentDay: 1,
    monthlyPaymentDelay: 0,
    currency: "",
};

const num = (textOrNumber) => {
    if (typeof textOrNumber === "number") {
        return textOrNumber;
    }
    return parseFloat(textOrNumber);
};

interface Props {
    paymentModel: PaymentModel | null;
    isAdding?: boolean;
    /** Called after a batch is created or edited */
    onChange: (updatedModel: PaymentModel) => void;
    /** Called if the menu is closed without changes */
    onClose: () => void;
}
export const EditPaymentModelForm = (props: Props) => {
    const [model, setModel] = React.useState<PaymentModel | null>(props.paymentModel || { ...NEW_MODEL });

    const [isLoading, setIsLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const updatePaymentModel = async () => {
        try {
            model.upfrontCost = num(model.upfrontCost);
            model.monthlyCost = num(model.monthlyCost);
            model.numberOfMonths = num(model.numberOfMonths);
            model.monthlyPaymentDay = num(model.monthlyPaymentDay);
            model.monthlyPaymentDelay = num(model.monthlyPaymentDelay);

            if (!validateFields()) {
                setErrorMessage("An error occurred parsing the numeric inputs. Check your values and try again");
                return;
            }

            setIsLoading(true);
            const route = props.isAdding ? "/api/paymentModels" : `/api/paymentModels/${model._id}`;
            const method = props.isAdding ? "POST" : "PUT";
            await makeRequest(route, method, model);
            props.onChange(model);
        } catch (ex) {
            alert("Error occurred saving changes to payment model: " + ex.message);
        }
        setIsLoading(false);
    };

    const validateFields = () => {
        if (typeof model.upfrontCost !== "number" || isNaN(model.upfrontCost)) {
            return false;
        }
        if (typeof model.monthlyCost !== "number" || isNaN(model.monthlyCost)) {
            return false;
        }
        if (typeof model.numberOfMonths !== "number" || isNaN(model.numberOfMonths)) {
            return false;
        }
        if (typeof model.monthlyPaymentDay !== "number" || isNaN(model.monthlyPaymentDay)) {
            return false;
        }
        if (typeof model.monthlyPaymentDelay !== "number" || isNaN(model.monthlyPaymentDelay)) {
            return false;
        }

        return true;
    };

    let totalCost = num(model.upfrontCost) + num(model.monthlyCost) * num(model.numberOfMonths);
    if (isNaN(totalCost) || typeof (totalCost) !== "number") {
        totalCost = 0;
    }

    return (<div>
        <div>
            <label>Payment model name</label>
            <input type="text" value={model.name} onChange={e => setModel({ ...model, name: e.target.value })} />
        </div>
        <div>
            <label>
                <MarkdownTip />
                <br />
                Details
            </label>
            <textarea rows={4} cols={60} value={model.details} onChange={e => setModel({ ...model, details: e.target.value })} />
        </div>
        <div>
            <label>Upfront Cost</label>
            <input type="text" value={model.upfrontCost} onChange={e => setModel({ ...model, upfrontCost: e.target.value as any })} />
        </div>
        <div>
            <label>Monthly Cost</label>
            <input type="text" value={model.monthlyCost} onChange={e => setModel({ ...model, monthlyCost: e.target.value as any })} />
        </div>
        <div>
            <label>Number of Months</label>
            <input type="text" value={model.numberOfMonths} onChange={e => setModel({ ...model, numberOfMonths: e.target.value as any })} />
        </div>
        <div>
            <label>Monthly payment due on</label>
            <input type="text" value={model.monthlyPaymentDay} onChange={e => setModel({ ...model, monthlyPaymentDay: e.target.value as any })} />
        </div>
        <TextInput object={model} onChange={setModel} propName="monthlyPaymentDelay" label="# months before monthly payments begin" />
        <div>
            <label>Currency</label>
            <input type="text" value={model.currency} onChange={e => setModel({ ...model, currency: e.target.value })} />
        </div>

        <div>
            Total cost: {totalCost} {model.currency}
        </div>

        { errorMessage && (<div style={{ color: "red" }}>{errorMessage}</div>)}
        <div style={{ marginTop: "20px" }}>
            <button disabled={isLoading} type="button" onClick={updatePaymentModel}>
                {isLoading && <LoadingIndicator />}
                Save changes
            </button>
            <button disabled={isLoading} className="secondary" type="button" onClick={props.onClose}>Cancel</button>
        </div>
    </div >);
};