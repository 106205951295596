import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Link } from "react-router-dom";
import { Application, Batch } from "../api";
import { Card } from "../components/Card";

interface Props {
    application: Application;
    batches: Batch[] | null;
}
export const ApplicationCard = (props: Props) => {
    const batch = props.batches?.find(b => b._id === props.application.batchId)
    return (<Link to={`/application/${props.application._id}`}>
        <Card>
            <FontAwesomeIcon icon="file-alt" size="1x" />
            <span style={{ marginLeft: "20px" }}>
                {batch?.name || "Your application"}
            </span>
        </Card>
    </Link>);
};