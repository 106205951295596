import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const Body = styled.div`
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
`;
const Header = styled.div`
  border-bottom: 1px solid #ccc;
  cursor: pointer;
  color: #555;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 300px;

  :hover {
    background-color: white;
    color: #333;
  }
`;
const Content = styled.div`
  padding: 10px;
`;

interface Props {
  title: string;
  children: React.ReactNode;
  style?: React.CSSProperties;
}
export const Expandable = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <Body className="Expandable" style={props.style}>
      <Header onClick={() => setIsOpen(!isOpen)}>
        <span style={{marginRight: "20px"}}>{props.title}</span>
        <FontAwesomeIcon icon={isOpen ? "chevron-up" : "chevron-down"} />
      </Header>
      {isOpen && <Content>{props.children}</Content>}
    </Body>
  );
};
