import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { makeRequest, Webhook } from "../../common/api";
import { LoadingIndicator } from "../../common/features/Utils";

interface Props {
  webhook: Webhook | null;
  onSave: (webhook: Webhook) => Promise<void>;
  onClose: () => void;
}
export const EditWebhookForm = (props: Props) => {
  const [webhook, setWebhook] = React.useState<Webhook>(
    props.webhook ||
      ({
        name: "",
        targetUrl: "",
      } as any)
  );

  const [isLoading, setIsLoading] = React.useState(false);
  const testWebhook = () => {
    if (!props.webhook) {
      return;
    }

    makeRequest(`/api/sendWebhook/${props.webhook._id}`, "POST").then(
      (response) => {
        alert("Done: " + JSON.stringify(response));
      }
    );
  };

  return (
    <div>
      <div>
        <label>Webhook Name</label>
        <input
          type="text"
          value={webhook.name}
          onChange={(e) => setWebhook({ ...webhook, name: e.target.value })}
        />
      </div>
      <div>
        <label>Target URL</label>
        <input
          type="text"
          value={webhook.targetUrl}
          onChange={(e) =>
            setWebhook({ ...webhook, targetUrl: e.target.value })
          }
        />
      </div>

      <div style={{ marginTop: "20px" }}>
        {props.webhook ? (
          <div>
            <h4>Test</h4>
            <button className="inline secondary" onClick={() => testWebhook()}>Send test event</button>
          </div>
        ) : (
          <div>Save your webhook then return here to test it out.</div>
        )}
      </div>

      <div style={{ marginTop: "20px" }}>
        <button
          disabled={isLoading}
          type="button"
          onClick={async () => {
            setIsLoading(true);
            await props.onSave(webhook);
            setIsLoading(false);
          }}
        >
          {isLoading && <LoadingIndicator />}
          Save changes
        </button>
        <button
          disabled={isLoading}
          className="secondary"
          type="button"
          onClick={props.onClose}
        >
          <FontAwesomeIcon icon="times" /> Cancel
        </button>
      </div>
    </div>
  );
};
